import React, { useState, useMemo } from 'react';
import Layout from '../components/shared/Layout';
import { User, MapPin, Heart, Briefcase, Book, Music, Film, Coffee, ArrowUpDown, ChevronUp, ChevronDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

const entityTypes = [
  { name: 'All', icon: null },
  { name: 'People', icon: <User size={18} /> },
  { name: 'Places', icon: <MapPin size={18} /> },
  { name: 'Interests', icon: <Heart size={18} /> },
  { name: 'Work', icon: <Briefcase size={18} /> },
  { name: 'Hobbies', icon: <Coffee size={18} /> },
];

const mockEntities = [
  { id: 1, type: 'People', name: 'Sarah Johnson', bio: 'Best friend since college', tags: ['friend', 'confidant'], impact: 95, connections: 15, icon: <User size={24} className="text-blue-500" /> },
  { id: 2, type: 'Places', name: 'Central Park', bio: 'Favorite spot for weekend runs', tags: ['nature', 'exercise'], impact: 80, connections: 10, icon: <MapPin size={24} className="text-green-500" /> },
  { id: 3, type: 'Interests', name: 'Machine Learning', bio: 'Fascinated by AI and its applications', tags: ['technology', 'career'], impact: 90, connections: 20, icon: <Book size={24} className="text-purple-500" /> },
  { id: 4, type: 'Work', name: 'TechCorp Inc.', bio: 'Current workplace, software engineer', tags: ['career', 'programming'], impact: 85, connections: 25, icon: <Briefcase size={24} className="text-yellow-500" /> },
  { id: 5, type: 'Hobbies', name: 'Guitar', bio: 'Playing for 5 years, love acoustic', tags: ['music', 'creativity'], impact: 75, connections: 8, icon: <Music size={24} className="text-red-500" /> },
  { id: 6, type: 'People', name: 'Dr. Emily Chen', bio: 'Mentor and career advisor', tags: ['professional', 'mentor'], impact: 88, connections: 12, icon: <User size={24} className="text-blue-500" /> },
  { id: 7, type: 'Interests', name: 'Sci-Fi Movies', bio: 'Huge fan of dystopian futures', tags: ['entertainment', 'relaxation'], impact: 70, connections: 6, icon: <Film size={24} className="text-indigo-500" /> },
  // Add more mock entities as needed
];

const SmartCard = ({ entity }) => (
  <motion.div
    layout
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3 }}
    className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-700"
  >
    <Link to="/cards/1">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <div className="p-2 bg-gray-700 rounded-full">{entity.icon}</div>
          <h3 className="text-xl font-semibold ml-3 text-white">{entity.name}</h3>
        </div>
        <span className="text-sm font-medium px-2 py-1 bg-purple-600 text-white rounded-full">
          {entity.type}
        </span>
      </div>
      <p className="text-sm text-gray-300 mb-4">{entity.bio}</p>
      <div className="flex flex-wrap gap-2 mb-4">
        {entity.tags.map((tag, index) => (
          <span key={index} className="text-xs bg-gray-700 text-gray-300 px-2 py-1 rounded-full">
            #{tag}
          </span>
        ))}
      </div>
      <div className="flex justify-between text-sm font-medium">
        <div className="flex items-center">
          <Heart size={16} className="text-red-500 mr-1" />
          <span className="text-gray-300">Impact: </span>
          <span className="text-white ml-1">{entity.impact}%</span>
        </div>
        <div className="flex items-center">
          <User size={16} className="text-blue-500 mr-1" />
          <span className="text-gray-300">Connections: </span>
          <span className="text-white ml-1">{entity.connections}</span>
        </div>
      </div>
    </Link>
  </motion.div>
);

const SortButton = ({ label, sortKey, currentSort, onClick }) => (
  <button
    onClick={() => onClick(sortKey)}
    className={`flex items-center space-x-1 px-3 py-2 rounded-md text-sm ${currentSort.key === sortKey ? 'bg-purple-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
      }`}
  >
    <span>{label}</span>
    {currentSort.key === sortKey ? (
      currentSort.direction === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />
    ) : (
      <ArrowUpDown size={16} />
    )}
  </button>
);

const CardsPage = () => {
  const [selectedType, setSelectedType] = useState('All');
  const [currentSort, setCurrentSort] = useState({ key: 'impact', direction: 'desc' });

  const handleSort = (key) => {
    setCurrentSort(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  const filteredAndSortedEntities = useMemo(() => {
    let filtered = selectedType === 'All'
      ? mockEntities
      : mockEntities.filter(entity => entity.type === selectedType);

    return filtered.sort((a, b) => {
      if (currentSort.direction === 'asc') {
        return a[currentSort.key] - b[currentSort.key];
      } else {
        return b[currentSort.key] - a[currentSort.key];
      }
    });
  }, [selectedType, currentSort]);

  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-white">Your Life Cards</h1>

        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 space-y-4 md:space-y-0">
          <div className="flex flex-wrap gap-2">
            {entityTypes.map((type) => (
              <button
                key={type.name}
                onClick={() => setSelectedType(type.name)}
                className={`flex items-center px-3 py-2 rounded-full text-sm ${selectedType === type.name
                  ? 'bg-purple-600 text-white'
                  : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  }`}
              >
                {type.icon && <span className="mr-2">{type.icon}</span>}
                {type.name}
              </button>
            ))}
          </div>
          <div className="flex space-x-2">
            <SortButton label="Impact" sortKey="impact" currentSort={currentSort} onClick={handleSort} />
            <SortButton label="Connections" sortKey="connections" currentSort={currentSort} onClick={handleSort} />
          </div>
        </div>

        <AnimatePresence>
          <motion.div layout className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredAndSortedEntities.map((entity) => (
              <SmartCard key={entity.id} entity={entity} />
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    </Layout>
  );
};

export default CardsPage;