// App.js

import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import ProtectedRoute from './ProtectedRoute';
import { Routes, Route } from 'react-router-dom';

import AppList from './pages/AppsList';
import AppSetup from './pages/AppSetup';
import Dashboard from './pages/Dashboard';
import NotFoundPage from './pages/NotFoundPage';

import LifeGraphFlow from './pages/LifeGraphFlow';

import LandingPage from './pages/LandingPage';
import HomePage from './pages/HomePage';
import CardsPage from './pages/CardsPage';
import AskPage from './pages/AskPage';
import Onboarding from './pages/Onboarding';
import DataWizardPage from './pages/DataWizardPage';
import DataHub from './pages/DataHubPage';
import CardPage from './pages/CardPage';
import LifeGraphPage from './pages/LifegraphPage';
import ReflectionSession from './pages/ReflectionSession';
import ReflectionSummary from './pages/ReflectionSummary';
import SessionsListPage from './pages/SessionsListPage';

function App() {
  return (
    // <ThemeProvider>
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/reflect" element={<ProtectedRoute><ReflectionSession /></ProtectedRoute>} />
        <Route path="/reflect/:sessionId" element={<ProtectedRoute><ReflectionSession /></ProtectedRoute>} />
        <Route path="/reflect/summary" element={<ProtectedRoute><ReflectionSummary /></ProtectedRoute>} />
        <Route path="/reflect/sessions" element={<ProtectedRoute><SessionsListPage /></ProtectedRoute>} />

        <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
        <Route path="/cards" element={<ProtectedRoute><CardsPage /></ProtectedRoute>} />
        <Route path="/cards/:id/" element={<ProtectedRoute><CardPage /></ProtectedRoute>} />
        <Route path="/life-graph" element={<ProtectedRoute><LifeGraphPage /></ProtectedRoute>} />
        <Route path="/ask" element={<ProtectedRoute><AskPage /></ProtectedRoute>} />
        <Route path="/onboarding" element={<ProtectedRoute><Onboarding /></ProtectedRoute>} />
        <Route path="/data-wizard" element={<ProtectedRoute><DataWizardPage /></ProtectedRoute>} />
        <Route path="/data-hub" element={<ProtectedRoute><DataHub /></ProtectedRoute>} />


        {/* <Route path="/ask" element={<ProtectedRoute><Ask /></ProtectedRoute>} /> */}
        <Route path="/lifegraph/flow" element={<ProtectedRoute><LifeGraphFlow /></ProtectedRoute>} />

        {/* <Route path="/lifegraph/:node" element={<ProtectedRoute><NodeDetails /></ProtectedRoute>} /> */}
        <Route path="/apps" element={<ProtectedRoute><AppList /></ProtectedRoute>} />
        <Route path="/apps/:app/:action/:sub" element={<AppSetup />} />
        {/* Catch all other routes */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </HelmetProvider>
    // </ThemeProvider>
  );
}

export default App;
