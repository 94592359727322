import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Loader, Mic, X, Volume2, Sun, Moon, MessageCircle, ChevronLeft, List } from 'lucide-react';
import Layout from '../components/shared/Layout';
import SmartCard from '../components/SmartCard';
import { useApi } from '../callApi';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import StatusMonitor from '../components/StatusMonitor';  // Import the new StatusMonitor component.

import BackgroundAnimation from '../components/BackgroundAnimation';
import Teleprompter from '../components/Teleprompter';

const SummaryPoints = ({ points }) => (
  <div className="bg-gray-800 rounded-lg p-4 mb-4">
    <h3 className="text-lg font-semibold mb-2 text-white">Session Summary</h3>
    <ul className="list-disc list-inside text-sm text-gray-300">
      {points.map((point, index) => (
        <li key={index}>{point}</li>
      ))}
    </ul>
  </div>
);




const TalkingPoint = ({ point, onClick }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    className="bg-purple-600 text-white p-2 rounded-lg mb-2 cursor-pointer hover:bg-purple-700 transition-colors duration-200"
    onClick={onClick}
  >
    <MessageCircle className="inline-block mr-2" size={16} />
    {point}
  </motion.div>
);

const ReflectionSession = ({ }) => {
  const { sessionId } = useParams();
  const [searchParams] = useSearchParams();

  const isFirstTime = searchParams.get('first_time');

  const navigate = useNavigate();
  const { callApi } = useApi();
  const OPENAI_KEY = process.env.REACT_APP_OPENAI_API_KEY;
  const [sessionUUID, setSessionUUID] = useState(sessionId);
  const [globalStatus, setGlobalStatus] = useState(null);

  const [sessionState, setSessionState] = useState('preparation');
  const [chatState, setChatState] = useState('idle');
  const [animationProgress, setAnimationProgress] = useState(0);
  const [ambience, setAmbience] = useState('day');
  const [volume, setVolume] = useState(5);
  const [showLayout, setShowLayout] = useState(false);
  const [summaryPoints, setSummaryPoints] = useState([]);
  const [talkingPoints, setTalkingPoints] = useState([]);
  const [currentMode, setCurrentMode] = useState([]);
  const [currentTranscript, setCurrentTranscript] = useState('');

  const [currentEntity, setCurrentEntity] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const messages = []; const recognition = useRef(null);
  const audioPlayer = useRef(new Audio());
  const microphone = useRef(null);
  const musicPlayer = useRef(null);

  /** State Effects */
  // Effect for saving on component unmount

  useEffect(() => {
    if (sessionId) {
      initializeSession();
    }
  }, [sessionId]);

  useEffect(() => {
    musicPlayer.current = new Audio('https://qtwobmfyhbevtzpgzxgu.supabase.co/storage/v1/object/public/assets/hearty.mp3');
    musicPlayer.current.loop = true;
    return () => {
      if (musicPlayer.current) {
        musicPlayer.current.pause();
        musicPlayer.current.src = '';
      }
    };
  }, []);

  useEffect(() => {
    musicPlayer.current.volume = volume / 100;
    if (sessionState === 'active' && chatState !== 'idle') {
      //   musicPlayer.current.play();
    } else {
      //  musicPlayer.current.pause();
    }
  }, [volume, sessionState, chatState]);

  useEffect(() => {
    let interval;
    if (chatState === 'listening' || chatState === 'speaking') {
      interval = setInterval(() => {
        setAnimationProgress((prev) => (prev + 1) % 100);
      }, 50);
    } else {
      setAnimationProgress(0);
    }
    return () => clearInterval(interval);
  }, [chatState]);


  useEffect(() => {
    if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognition.current = new SpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.lang = 'en-US';

      recognition.current.onstart = () => {
        console.log('Speech recognition started');
      };

      recognition.current.onresult = (event) => {
        let interimTranscript = '';
        let finalTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }


        if (finalTranscript.length > 1) {
          getAIReflecterResponse(finalTranscript);

          messages.push({ text: finalTranscript, user: 'ahmed' });
        }
      };

      recognition.current.onend = () => {
        console.log('Speech recognition stopped ' + chatState);
        if (chatState !== 'speaking') {
          //setChatState('idle')

        }
      };

      recognition.current.onerror = (event) => {
        console.error('SpeechRecognition error' + chatState, event.error);
        if (chatState !== 'speaking') {
          setChatState('idle')

        }

        stopListening()

        setError('Speech recognition error');
      };
    } else {
      setError('Speech recognition not supported in this browser');
    }

    return () => {
      if (recognition.current) {
        recognition.current.stop();
      }
    };
  }, []);

  /** */



  /** Events Handlers */
  const playAudio = (base64Audio) => {
    const audioBlob = new Blob([base64Audio], { type: 'audio/wav' });
    const audioUrl = URL.createObjectURL(audioBlob);
    audioPlayer.current.src = audioUrl;

    audioPlayer.current.onended = () => {
      console.log("Audio playback finished, starting listening.");
      setChatState('listening')
      startListening();  // Automatically start listening after audio ends
    };

    audioPlayer.current.play();
  };

  const startListening = async () => {
    setCurrentTranscript('')
    stopSpeaking();  // Stop any ongoing AI speech to avoid conflicts

    try {
      setError(null);
      recognition.current.start();

    } catch (error) {
      console.error('Error accessing microphone:', error);
      setError('Error accessing microphone');

    }
  };

  // Session initialization

  const initializeSession = async () => {
    setGlobalStatus('loading');
    setIsLoading(true);
    try {
      if (sessionId) {
        // Resuming an existing session
        const sessionData = await loadSession(sessionId);
        if (sessionData) {
          setSessionUUID(sessionId);
          updateSessionState(sessionData);
          setSessionState('active');
        } else {
          setError('Session not found');
          return;
        }
      } else {
        // Starting a new session
        const newSessionId = await createNewSession();
        setSessionUUID(newSessionId);
        navigate(`/reflect/${newSessionId}${isFirstTime ? '?first_time=true' : ''}`, { replace: true });
        setSessionState('active');
      }
    } catch (error) {
      console.error('Error initializing session:', error);
      setError('Failed to initialize session');
    } finally {
      setIsLoading(false);
      setGlobalStatus(null);
    }
  };


  const stopListening = () => {

    if (microphone.current) {
      microphone.current.disconnect();
    }

    if (recognition.current) {
      recognition.current.stop();
    }


  };

  const stopSpeaking = () => {
    if (audioPlayer.current) {
      audioPlayer.current.pause();
      audioPlayer.current.currentTime = 0;  // Reset the playback position
    }
  };

  const toggleLayout = () => {
    setShowLayout(!showLayout);
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    musicPlayer.current.volume = newVolume / 100;
  };
  const handleReadyClick = () => {
    if (!sessionUUID) {
      initializeSession();
    } else {
      setSessionState('active');
    }
  };


  const handleMainButtonClick = () => {
    if (sessionState === 'preparation') {
      setSessionState('active');
      setChatState('idle');
    } else {
      switch (chatState) {
        case 'idle':
          musicPlayer.current.play();

          setChatState('listening');
          startListening()
          break;
        case 'listening':

          setChatState('processing');
          stopListening()
          stopSpeaking()

          break;
        case 'speaking':
          stopSpeaking();
          setChatState('listening');
          startListening()
          break;
        case 'processing':
          setChatState('idle');
          break;
        default:
          setChatState('idle');
      }
    }
  };

  const updateSessionState = (sessionData) => {
    setSummaryPoints(sessionData.summary_points || []);
    setTalkingPoints(sessionData.talking_points || []);
    setCurrentMode(sessionData.current_mode || []);
    setCurrentEntity(sessionData.current_entity || null);
    // Add any other state updates as needed
  };

  const updateDynamicContent = async (response) => {

    if (response.main_entity) {
      setCurrentEntity({
        icon: <MessageCircle size={24} className="text-purple-500" />,
        name: response.main_entity.name || "Reflection Topic",
        type: response.main_entity.type || "Concept",
        bio: response.main_entity.description || "A key area of focus in your life right now.",
        tags: response.main_entity.tags || ["personal growth", "self-reflection", "mindfulness"],
        impact: response.main_entity.impact || Math.floor(Math.random() * 100),
        connections: response.main_entity.connections || Math.floor(Math.random() * 10)
      });
    }

    if (response.current_mode) {
      setCurrentMode(response.current_mode);
    }

    if (response.talking_points) {

      setTalkingPoints(!Array.isArray(response.talking_points) ? [response.talking_points] : response.talking_points);
    }

    if (response.summary_points) {
      setSummaryPoints(response.summary_points);
    }

  };

  /***
   * 
   *  API Calls
   */

  const getAIAudioFromText = async (inputText) => {
    try {
      const response = await fetch('https://api.openai.com/v1/audio/speech', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${OPENAI_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'tts-1',
          input: inputText,
          voice: 'alloy',
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }


      const blob = await response.blob();
      recognition.current.stop();
      return blob


    } catch (err) {
      setError('Failed to fetch audio from OpenAI.');
      console.error('Error fetching audio from OpenAI:', err);


    }
  };

  const getAIObserverResponse = async () => {
    //setGlobalStatus('observing');

    const response = await callApi(`/observer?session_id=${sessionUUID}`, {
      method: 'POST',
    }, { message: 'get observation', session_id: sessionUUID, stream: false, reflect: true });
    return response

  };

  const getAIReflecterResponse = async (text) => {

    setChatState('processing')

    try {
      const response = await callApi(`/chat?session_id=${sessionUUID}&first_time=${isFirstTime ? true : false}`, {
        method: 'POST',
      }, { message: text, stream: false, reflect: true });

      const data = response.message ? response.message : JSON.parse(response.choices[0].message.content);
      const message = data?.message || data;


      messages.push({ text: message, user: 'jurni-ai' });

      setChatState('speaking');

      const audioBlob = await getAIAudioFromText(message);
      playAudio(audioBlob);
      setCurrentTranscript(message)

      // Start Observer
      const observe = await getAIObserverResponse()

      updateDynamicContent(observe)

      // save progress
      observe.talking_points = !Array.isArray(observe.talking_points) ? [observe.talking_points] : observe.talking_points
      const saved = await saveSessionProgress(observe)

      // Start Graph Updater
      const updater = await doGraphUpdate(talkingPoints)

      setGlobalStatus(null)


    } catch (error) {
      console.error('Error processing or fetching AI response:', error);



    }


  };


  const doGraphUpdate = async (talkingPoints) => {
    setGlobalStatus('updating');

    try {
      const response = await callApi(`/update-graph?session_id=${sessionUUID}`, {
        method: 'POST',
      }, { message: JSON.stringify(talkingPoints), session_id: sessionUUID, reflect: true });

      if (response) {
        console.log('Graph updated successfully');
      } else {
        console.error('Failed to update graph:', response.message);
      }
    } catch (error) {
      console.error('Error updating graph:', error);
    }
  };

  const doConcludeSession = async () => {
    setGlobalStatus('concluding');

    try {
      const response = await callApi('/conclude-session/', {
        method: 'POST',
      });

      if (response.success) {
        setSessionSummary(response.data.summary);
        setSessionState('concluded');
      } else {
        console.error('Failed to conclude session:', response.message);
      }
    } catch (error) {
      console.error('Error concluding session:', error);
    }
  };
  // Session management functions
  const createNewSession = async () => {
    try {
      const response = await callApi('/create-session/', {
        method: 'POST',
      });
      return response.sessionId;
    } catch (error) {
      throw new Error('Failed to create new session');
    }
  };
  const saveSessionProgress = async (observerData) => {
    if (!sessionUUID) return;
    setGlobalStatus('saving');
    try {
      const sessionData = {
        summary_points: observerData.summary_points || [],
        talking_points: observerData.talking_points || [],
        current_mode: Array.isArray(observerData.current_mode) ? observerData.current_mode : [observerData.current_mode],
        current_entity: observerData.main_entity || null,
      };

      console.log('Saving session data:', sessionData);

      const response = await callApi(`/save-session/${sessionUUID}`, {
        method: 'POST',
        body: JSON.stringify(sessionData),
      });

      if (response.message === "Session saved successfully") {
        console.log('Session progress saved successfully');
      } else {
        console.error('Unexpected response when saving session:', response);
      }
    } catch (error) {
      console.error('Error saving session progress:', error);
    } finally {
      setGlobalStatus(null);
    }
  };


  const loadSession = async (sessionId) => {
    try {
      const response = await callApi(`/load-session/${sessionId}`, {
        method: 'GET',
      });
      return response.sessionData;
    } catch (error) {
      throw new Error('Failed to load session');
    }
  };




  /** */

  /** UI Renders */
  const getButtonStyles = () => {
    switch (chatState) {
      case 'listening':
        return 'bg-red-600 hover:bg-red-700';
      case 'processing':
        return 'bg-yellow-600 hover:bg-yellow-700';
      case 'speaking':
        return 'bg-green-600 hover:bg-green-700';
      default:
        return 'bg-purple-600 hover:bg-purple-700';
    }
  };

  const sidePanel = (
    <AnimatePresence>
      {sessionState === 'active' && (
        <motion.div
          initial={{ x: '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="w-full md:w-96 bg-gray-800 p-6 overflow-y-auto"
        >
          {currentEntity && (
            <SmartCard
              entity={currentEntity}
              onClose={() => setCurrentEntity(null)}
            />
          )}
          <SummaryPoints points={summaryPoints} />
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Talking Points</h3>
            {talkingPoints.map((point, index) => (
              <TalkingPoint key={index} point={point} onClick={() => { }} />
            ))}
          </div>

        </motion.div>
      )}
    </AnimatePresence>
  );

  const getButtonIcon = () => {
    switch (chatState) {
      case 'listening':
        return <Mic size={24} className="text-white" />;
      case 'processing':
        return <Loader size={24} className="text-white animate-spin" />;
      case 'speaking':
        return <MessageCircle size={24} className="text-white" />;
      default:
        return <Mic size={24} className="text-white" />;
    }
  };

  const getMainShape = () => {
    let fill = "#8B5CF6"; // Default purple

    switch (chatState) {
      case 'listening':
        return (
          <motion.circle
            cx="50%"
            cy="50%"
            r="45%"
            fill={fill}
            animate={{ scale: [1, 1.05, 1] }}
            transition={{ repeat: Infinity, duration: 1.5 }}
          />
        );
      case 'processing':
        return (
          <motion.circle
            cx="50%"
            cy="50%"
            r="45%"
            fill="#4B5563"
            animate={{ scale: [1, 0.95, 1] }}
            transition={{ repeat: Infinity, duration: 0.8 }}
          ></motion.circle>
        );
      case 'speaking':
        return (
          <motion.path
            d={`M50 10 A40 40 0 0 1 50 90 A40 40 0 0 1 50 10`}
            fill={fill}
            animate={{
              d: [
                "M50 10 A40 40 0 0 1 50 90 A40 40 0 0 1 50 10",
                "M50 20 A30 30 0 0 1 50 80 A30 30 0 0 1 50 20",
                "M50 10 A40 40 0 0 1 50 90 A40 40 0 0 1 50 10"
              ]
            }}
            transition={{ repeat: Infinity, duration: 1.5 }}
          />
        );
      default:
        return <circle cx="50%" cy="50%" r="45%" fill={fill} ></circle>;
    }
  };

  const mainContent = (
    <div className="flex-1 p-4 md:p-8 flex flex-col justify-center items-center relative">
      <BackgroundAnimation />
      <button
        onClick={toggleLayout}
        className="absolute top-4 left-4 p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition-colors duration-200 z-10"
      >
        {showLayout ? <ChevronLeft className="w-6 h-6" /> : <List className="w-6 h-6" />}
      </button>
      {sessionState === 'preparation' ? (
        <div className="max-w-2xl text-center relative z-10">
          <h2 className="text-3xl font-semibold mb-4">Prepare for Your Reflection Session</h2>
          <p className="mb-4">Find a quiet, comfortable space. Consider the following:</p>
          <ul className="list-disc list-inside mb-4 text-left">
            <li>Put on headphones for a more immersive experience</li>
            <li>Adjust the ambience to match your surroundings or mood</li>
            <li>Set the volume to a comfortable level for background music</li>
          </ul>
          <button
            onClick={() => handleReadyClick()}
            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
          >
            I'm Ready
          </button>
        </div>
      ) : (
        <>




          {chatState == 'speaking' && currentTranscript.length > 0 ? (

            <Teleprompter text={currentTranscript} />
          ) : (
            <div className="relative w-64 h-64 md:w-96 md:h-96 mb-8 z-10">
              <svg width="100%" height="100%" viewBox="0 0 100 100">
                {getMainShape()}
              </svg>

              <AnimatePresence>
                {chatState !== 'idle' && (
                  <motion.div
                    className="absolute bottom-0 left-0 right-0 flex justify-center items-center h-12"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                  >
                    <div className="bg-gray-700 bg-opacity-50 rounded-full px-4 py-1">
                      <div className="flex space-x-1">
                        {[...Array(5)].map((_, i) => (
                          <motion.div
                            key={i}
                            className="w-2 h-2 bg-purple-400 rounded-full"
                            animate={{ scale: i === animationProgress % 5 ? 1.5 : 1 }}
                          />
                        ))}
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>

            </div>
          )
          }

          <div className="flex items-center space-x-4 mb-4 z-10">
            <button
              onClick={handleMainButtonClick}
              className={`w-16 h-16 rounded-full flex items-center justify-center focus:outline-none transition-colors duration-200 ${getButtonStyles()}`}
            >
              {getButtonIcon()}
            </button>
            <button
              onClick={() => setSessionState('preparation')}
              className="w-16 h-16 bg-red-500 rounded-full flex items-center justify-center focus:outline-none hover:bg-red-600 transition-colors duration-200"
            >
              <X className="w-8 h-8 text-white" />
            </button>
          </div>
          <div className="flex items-center space-x-4 z-10">
            <button
              onClick={() => setAmbience(ambience === 'day' ? 'night' : 'day')}
              className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition-colors duration-200"
            >
              {ambience === 'day' ? (
                <Sun className="w-6 h-6 text-yellow-400" />
              ) : (
                <Moon className="w-6 h-6 text-blue-400" />
              )}
            </button>
            <div className="flex items-center">
              <Volume2 className="w-6 h-6 text-gray-400 mr-2" />
              <input
                type="range"
                min="0"
                max="100"
                value={volume}
                onChange={handleVolumeChange}
                className="w-24 accent-purple-600"
              />
            </div>
          </div>
        </>
      )}
    </div>
  );


  /** */

  if (showLayout) {
    return (
      <Layout>
        <div className="flex flex-col md:flex-row h-full">
          {mainContent}
          {sidePanel}
        </div>
      </Layout>
    );
  }

  return (
    <div className="h-screen w-screen bg-gray-900 text-white overflow-hidden flex flex-col md:flex-row">
      {mainContent}
      {sidePanel}
      <StatusMonitor status={globalStatus} />

    </div>
  );
};

export default ReflectionSession;