// components/Button.js
import React from 'react';
import { Loader } from 'lucide-react';

const Button = ({ children, isLoading, disabled, className, onClick, ...props }) => {
  return (
    <button
      className={`px-4 py-2 rounded-lg transition-colors ${isLoading || disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-opacity-80'
        } ${className}`}
      onClick={onClick}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading ? (
        <>
          <Loader className="inline-block mr-2 animate-spin" size={16} />
          Loading...
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;