import React, { useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Calendar, Grid, List, Clock, User, MapPin, Heart, Briefcase, Coffee, Search, Filter } from 'lucide-react';
import Layout from '../components/shared/Layout';
import { format, addDays, subDays, startOfWeek, endOfWeek, eachDayOfInterval } from 'date-fns';

const entityIcons = {
  People: <User size={16} />,
  Places: <MapPin size={16} />,
  Interests: <Heart size={16} />,
  Work: <Briefcase size={16} />,
  Hobbies: <Coffee size={16} />
};

const mockSessions = {
  '2024-07-17': {
    id: 1,
    summary: "Reflected on work-life balance. Set new boundaries for project deadlines.",
    duration: '15 min',
    entities: [
      { type: 'People', name: 'Sarah Johnson' },
      { type: 'Work', name: 'TechCorp Inc.' }
    ],
    tags: ['productivity', 'stress-management']
  },
  '2024-07-16': {
    id: 2,
    summary: "Practiced Spanish for 30 minutes. Had a breakthrough with verb conjugations.",
    duration: '30 min',
    entities: [
      { type: 'Hobbies', name: 'Language Learning' },
      { type: 'People', name: 'Spanish Tutor' }
    ],
    tags: ['language-learning', 'personal-growth']
  },
  '2024-07-15': {
    id: 3,
    summary: "Meditated for 20 minutes focusing on gratitude. Noticed increased positivity throughout the day.",
    duration: '20 min',
    entities: [
      { type: 'Interests', name: 'Mindfulness' }
    ],
    tags: ['mindfulness', 'well-being']
  }
};

const SessionCard = ({ session }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    className="bg-gray-800 rounded-lg shadow-lg p-6 mb-4"
  >
    <div className="flex justify-between items-center mb-2">
      <h3 className="text-lg font-semibold text-white">{format(new Date(session.id), 'MMMM d, yyyy')}</h3>
      <span className="text-sm text-gray-400">{session.duration}</span>
    </div>
    <p className="text-gray-300 mb-3">{session.summary}</p>
    <div className="flex flex-wrap gap-2 mb-2">
      {session.entities.map((entity, index) => (
        <span key={index} className="flex items-center text-xs bg-gray-700 text-gray-300 px-2 py-1 rounded-full">
          {entityIcons[entity.type]}
          <span className="ml-1">{entity.name}</span>
        </span>
      ))}
    </div>
    <div className="flex flex-wrap gap-2">
      {session.tags.map((tag, index) => (
        <span key={index} className="text-xs bg-purple-900 text-purple-200 px-2 py-1 rounded-full">
          #{tag}
        </span>
      ))}
    </div>
  </motion.div>
);

const DayView = ({ date, sessions }) => (
  <div>
    <h2 className="text-xl font-bold text-white mb-4">{format(date, 'MMMM d, yyyy')}</h2>
    {sessions[format(date, 'yyyy-MM-dd')] ? (
      <SessionCard session={sessions[format(date, 'yyyy-MM-dd')]} />
    ) : (
      <p className="text-gray-500 italic">No reflection session for this day.</p>
    )}
  </div>
);

const WeekView = ({ startDate, sessions }) => {
  const weekDays = eachDayOfInterval({
    start: startOfWeek(startDate),
    end: endOfWeek(startDate)
  });

  return (
    <div className="grid grid-cols-7 gap-4">
      {weekDays.map(day => (
        <div key={day.toString()} className="bg-gray-800 p-2 rounded">
          <h3 className="text-sm font-semibold text-white mb-2">{format(day, 'EEE d')}</h3>
          {sessions[format(day, 'yyyy-MM-dd')] ? (
            <div className="text-xs text-gray-300">{sessions[format(day, 'yyyy-MM-dd')].summary.substring(0, 50)}...</div>
          ) : (
            <p className="text-xs text-gray-500 italic">No session</p>
          )}
        </div>
      ))}
    </div>
  );
};

const CalendarView = ({ date, sessions }) => {
  // Simplified calendar view - you might want to expand this
  return (
    <div className="grid grid-cols-7 gap-2">
      {eachDayOfInterval({
        start: startOfWeek(date),
        end: endOfWeek(addDays(date, 28))
      }).map(day => (
        <div key={day.toString()} className={`p-2 ${sessions[format(day, 'yyyy-MM-dd')] ? 'bg-purple-900' : 'bg-gray-800'} rounded`}>
          {format(day, 'd')}
        </div>
      ))}
    </div>
  );
};

const SessionsListPage = () => {
  const [currentDate, setCurrentDate] = useState(new Date('2024-07-17'));
  const [viewMode, setViewMode] = useState('day');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const filteredSessions = useMemo(() => {
    return Object.entries(mockSessions).filter(([date, session]) => {
      const matchesSearch = session.summary.toLowerCase().includes(searchTerm.toLowerCase()) ||
        session.entities.some(entity => entity.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        session.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()));

      const matchesEntities = selectedEntities.length === 0 ||
        session.entities.some(entity => selectedEntities.includes(entity.type));

      const matchesTags = selectedTags.length === 0 ||
        session.tags.some(tag => selectedTags.includes(tag));

      return matchesSearch && matchesEntities && matchesTags;
    }).reduce((acc, [date, session]) => ({ ...acc, [date]: session }), {});
  }, [searchTerm, selectedEntities, selectedTags]);

  const goToPreviousDay = () => setCurrentDate(prevDate => subDays(prevDate, 1));
  const goToNextDay = () => setCurrentDate(prevDate => addDays(prevDate, 1));

  return (
    <Layout>
      <div className="max-w-6xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-white">Your Reflection Journal</h1>

        <div className="flex justify-between items-center mb-6">
          <div className="flex space-x-2">
            <button onClick={() => setViewMode('day')} className={`p-2 rounded ${viewMode === 'day' ? 'bg-purple-600' : 'bg-gray-700'}`}>
              <List size={20} />
            </button>
            <button onClick={() => setViewMode('week')} className={`p-2 rounded ${viewMode === 'week' ? 'bg-purple-600' : 'bg-gray-700'}`}>
              <Grid size={20} />
            </button>
            <button onClick={() => setViewMode('calendar')} className={`p-2 rounded ${viewMode === 'calendar' ? 'bg-purple-600' : 'bg-gray-700'}`}>
              <Calendar size={20} />
            </button>
          </div>
          <div className="flex items-center space-x-2">
            <button onClick={goToPreviousDay} className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 text-white">
              <ChevronLeft size={24} />
            </button>
            <div className="text-lg font-semibold text-gray-300">
              {format(currentDate, 'MMMM yyyy')}
            </div>
            <button onClick={goToNextDay} className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 text-white">
              <ChevronRight size={24} />
            </button>
          </div>
        </div>

        <div className="mb-6 flex space-x-4">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Search sessions..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full bg-gray-700 text-white rounded-full py-2 pl-10 pr-4"
            />
            <Search size={20} className="absolute left-3 top-2 text-gray-400" />
          </div>
          <button className="bg-gray-700 p-2 rounded-full">
            <Filter size={20} />
          </button>
        </div>

        <AnimatePresence>
          {viewMode === 'day' && <DayView date={currentDate} sessions={filteredSessions} />}
          {viewMode === 'week' && <WeekView startDate={currentDate} sessions={filteredSessions} />}
          {viewMode === 'calendar' && <CalendarView date={currentDate} sessions={filteredSessions} />}
        </AnimatePresence>
      </div>
    </Layout>
  );
};

export default SessionsListPage;