// hooks/useApiCall.js
import { useState } from 'react';
import { useApi } from '../callApi';
import { useNavigate } from "react-router-dom";

export const useApiCall = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { callApi } = useApi();
  const navigate = useNavigate();

  const makeApiCall = async (endpoint, method, data) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await callApi(endpoint, { method }, data);
      if (response.status >= 400) {
        throw new Error(response.detail || 'An unexpected error occurred');
      }
      if (!response.success) {
        if (response.new_user) {
          navigate('/onboarding')
          return
        }
        setError(response.message || 'Operation was not successful');
        return null;
      }
      return response.data;
    } catch (err) {
      setError(err.message || 'An unexpected error occurred');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, makeApiCall };
};