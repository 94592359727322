import Layout from "../components/shared/Layout";

const HomePage = () => {
  return (
    <Layout>

      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold mb-4 text-center text-white">Your AI-Powered Life Journal</h1>
        <p className="text-center text-gray-400 mb-8">Reflect, connect, and grow with a companion that truly understands you</p>

        <div className="relative mb-8">
          <input
            type="text"
            placeholder="Start your reflection..."
            className="w-full py-3 px-4 bg-gray-800 rounded-full focus:ring-2 focus:ring-purple-500 focus:outline-none"
          />
          <button className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-purple-600 hover:bg-purple-700 text-white font-semibold py-2 px-4 rounded-full transition-colors">
            Begin
          </button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {[
            { icon: "📚", title: "Intelligent Journaling", description: "Reflect, analyze, and grow with an AI companion that understands your journey." },
            { icon: "🧠", title: "Personalized Insights", description: "Gain deep understanding of your thoughts, patterns, and personal growth over time." },
            { icon: "🔗", title: "Seamless Integration", description: "Connect your social media, notes, and apps for a comprehensive life overview." },
            { icon: "📅", title: "Memory Timeline", description: "Visualize your experiences and growth over time." },
            { icon: "⚡", title: "Real-time Reflection", description: "Get instant feedback and prompts to deepen your self-awareness." },
            { icon: "💬", title: "Natural Conversations", description: "Interact with your journal using natural language for a seamless experience." }
          ].map((feature, index) => (
            <div key={index} className="bg-gray-800 p-4 rounded-lg hover:bg-gray-750 transition-all">
              <div className="text-2xl mb-2">{feature.icon}</div>
              <h3 className="text-lg font-semibold mb-2 text-white">{feature.title}</h3>
              <p className="text-sm text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

    </Layout>
  );
};
export default HomePage