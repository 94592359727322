import React from 'react';
import { motion } from 'framer-motion';
import { Loader, RefreshCw, Eye } from 'lucide-react';

const StatusMonitor = ({ status }) => {
  const getStatusIcon = () => {
    switch (status) {
      case 'loading':
        return <Loader className="animate-spin" />;
      case 'updating':
        return <RefreshCw className="animate-spin" />;
      case 'observing':
        return <Eye className="animate-pulse" />;
      default:
        return null;
    }
  };

  const getStatusText = () => {
    switch (status) {
      case 'loading':
        return 'Loading session...';
      case 'updating':
        return 'Updating graph...';
      case 'observing':
        return 'Observing...';
      default:
        return status;
    }
  };

  if (!status) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="fixed bottom-4 right-4 bg-gray-800 bg-opacity-80 text-white px-3 py-2 rounded-full flex items-center space-x-2 z-50"
    >
      {getStatusIcon()}
      <span className="text-sm">{getStatusText()}</span>
    </motion.div>
  );
};

export default StatusMonitor;