import React, { useState, useEffect } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import Layout from '../components/shared/Layout';
import PageHeader from '../components/shared/PageHeader';
import { useApi } from '../callApi';
import ReactFlow, { Background, MiniMap, Controls } from 'reactflow';
import 'reactflow/dist/style.css';

const LifeGraphFlow = () => {
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const { callApi } = useApi();
  const nodes = [
    {
      "id": "1",
      "type": "input",
      "position": { "x": 250, "y": 5 },
      "data": { "label": "Scale Everest Minds" }
    },
    {
      "id": "2",
      "position": { "x": 250, "y": 100 },
      "data": { "label": "Develop Proprietary HealthTech Solutions" }
    },
    {
      "id": "3",
      "position": { "x": 250, "y": 200 },
      "data": { "label": "Secure Significant Market Share" }
    },
    // Additional nodes with x, y positions go here...
    {
      "id": "11",
      "type": "output",
      "position": { "x": 250, "y": 1000 },
      "data": { "label": "CTO at Roche" }
    }
  ]
  const edges = [
    { "id": "e1-2", "source": "1", "target": "2", "type": "smoothstep", "animated": true },
    { "id": "e2-3", "source": "2", "target": "3", "type": "smoothstep", "animated": true },
    // Additional edges go here...
    { "id": "e10-11", "source": "10", "target": "11", "type": "smoothstep", "animated": true }
  ]


  useEffect(() => {
    setGraphData({ nodes, edges })
    async function fetchData() {
      try {
        // This endpoint should return only top-level nodes like "People", "Places", etc.
        const response = await callApi('/lifegraph/top');
        const data = response

        const nodes = data.nodes.map((node, index) => ({
          id: node.id.toString(),
          data: { label: node.label },
          position: { x: 100 * index, y: 100 * index / 2 }, // Example positioning
          // style: { backgroundColor: node.color || 'lightgray' }
        }));


        const edges = data.links.map(link => ({
          id: `e${link.source}-${link.target}`,
          source: link.source.toString(),
          target: link.target.toString(),
          label: link.label
        }));

        setGraphData({ nodes: nodes, edges: edges });
      } catch (error) {
        console.error("Error fetching the graph data:", error);
      }
    }

    // fetchData();
  }, []);

  const convertToReactFlowFormat = (data) => {



    console.log(nodes, edges)

    return { nodes, edges };
  };

  const handleNodeClick = async node => {
    console.log(`Clicked on node: ${node.id}`);
    return

  };


  return (
    <Layout>
      <PageHeader title={'Life Graph'} />
      <div className="graph-chat-container">
        <div style={{ width: '400px', height: '400px' }} className="graph-component">
          <ReactFlow
            nodes={graphData.nodes}
            edges={graphData.edges}
            onNodeClick={handleNodeClick}
            style={{ width: '500px', height: '500px' }} // Set your desired size
          >
            <Background color="#aaa" gap={16} />
            <MiniMap />
            <Controls />
          </ReactFlow>
        </div>
      </div>
    </Layout>
  );



};

export default LifeGraphFlow;
