import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/shared/Layout';
import AppsList from './AppsList';
import DataReviewPage from './DataReviewPage';
import LifeGraphVisualization from './LifegraphPage';
import { ChevronLeft, ChevronRight, Check } from 'lucide-react';

const StepIndicator = ({ steps, currentStep }) => (
  <div className="flex justify-between items-center mb-8 px-4">
    {steps.map((step, index) => (
      <div key={index} className="flex items-center">
        <div className={`
          flex items-center justify-center w-10 h-10 rounded-full
          ${index < currentStep ? 'bg-purple-600 text-white' :
            index === currentStep ? 'bg-purple-600 text-white' : 'bg-gray-700 text-gray-400'}
          transition-colors duration-200
        `}>
          {index < currentStep ? (
            <Check size={20} />
          ) : (
            <span>{index + 1}</span>
          )}
        </div>
        <div className={`ml-2 text-sm ${index === currentStep ? 'text-white font-semibold' : 'text-gray-400'}`}>
          {step.title}
        </div>
        {index < steps.length - 1 && (
          <div className={`w-16 h-1 mx-2 ${index < currentStep ? 'bg-purple-600' : 'bg-gray-700'}`} />
        )}
      </div>
    ))}
  </div>
);

const DataWizardPage = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedApp, setSelectedApp] = useState(null);
  const [extractedData, setExtractedData] = useState(null);
  const [reviewedData, setReviewedData] = useState(null);
  const navigate = useNavigate();

  const steps = [
    {
      title: "Connect",
      component: <AppsList onAppSelected={(app) => {
        setSelectedApp(app);
        setCurrentStep(1);
      }} />
    },
    {
      title: "Review",
      component: <DataReviewPage
        initialData={extractedData}
        onDataReviewed={(data) => {
          setReviewedData(data);
          setCurrentStep(2);
        }}
      />
    },
    {
      title: "Visualize",
      component: <LifeGraphVisualization data={reviewedData} />
    }
  ];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleFinish = () => {
    // Navigate to the main dashboard or life graph view
    navigate('/dashboard');
  };

  return (
    <Layout>
      <div className=" mx-auto py-8">
        <h1 className="text-3xl font-bold text-white mb-6 text-center">Integrate Your Data</h1>

        <StepIndicator steps={steps} currentStep={currentStep} />

        <div className="bg-gray-800 rounded-lg p-6 mb-8">
          {steps[currentStep].component}
        </div>

        <div className="flex justify-between">
          {currentStep > 0 && (
            <button
              onClick={handlePrevious}
              className="px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-600 transition-colors flex items-center"
            >
              <ChevronLeft size={20} className="mr-2" />
              Previous
            </button>
          )}
          {currentStep < steps.length - 1 ? (
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors flex items-center ml-auto"
            >
              Next
              <ChevronRight size={20} className="ml-2" />
            </button>
          ) : (
            <button
              onClick={handleFinish}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors ml-auto"
            >
              Finish
            </button>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default DataWizardPage;