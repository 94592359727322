import React, { useState, useEffect } from 'react';
import Layout from '../components/shared/Layout';
import { useApi } from '../callApi';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, File, Clipboard, MessageSquare, Loader, MapPin, FileArchive, Calendar, Instagram, Linkedin, Edit2, X, Check, AlertTriangle, Heart, User } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import MessageDisplay from '../components/MessageDisplay';
import { useApiCall } from '../hooks/useApiCall';

const DataSourceButton = ({ icon: Icon, label, onClick }) => (
  <button
    onClick={onClick}
    className="flex flex-col items-center justify-center p-4 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
  >
    <Icon size={24} className="mb-2 text-purple-500" />
    <span className="text-sm text-gray-300">{label}</span>
  </button>
);

const SmartCard = ({ entity, onEdit, onDelete }) => (
  <motion.div
    layout
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3 }}
    className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg p-4 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-700"
  >
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center">
        <div className="p-2 bg-gray-700 rounded-full">{entity.icon}</div>
        <h3 className="text-lg font-semibold ml-3 text-white">{entity.name}</h3>
      </div>
      <span className="text-xs font-medium px-2 py-1 bg-purple-600 text-white rounded-full">
        {entity.type}
      </span>
    </div>
    <p className="text-sm text-gray-300 mb-4">{entity.bio}</p>
    <div className="flex flex-wrap gap-2 mb-4">
      {entity.tags.map((tag, index) => (
        <span key={index} className="text-xs bg-gray-700 text-gray-300 px-2 py-1 rounded-full">
          #{tag}
        </span>
      ))}
    </div>
    <div className="flex justify-between text-sm font-medium">
      <div className="flex items-center">
        <Heart size={16} className="text-red-500 mr-1" />
        <span className="text-gray-300">Impact: </span>
        <span className="text-white ml-1">{entity.impact}%</span>
      </div>
      <div className="flex items-center">
        <User size={16} className="text-blue-500 mr-1" />
        <span className="text-gray-300">Connections: </span>
        <span className="text-white ml-1">{entity.connections}</span>
      </div>
    </div>
    <div className="flex justify-end mt-4 space-x-2">
      <button onClick={() => onEdit(entity)} className="text-blue-400 hover:text-blue-300">
        <Edit2 size={18} />
      </button>
      <button onClick={() => onDelete(entity.id)} className="text-red-400 hover:text-red-300">
        <X size={18} />
      </button>
    </div>
  </motion.div>
);

const DataHub = () => {
  const navigate = useNavigate();
  const [inputMethod, setInputMethod] = useState(null);
  const [inputText, setInputText] = useState(
    "Had a great meeting with Sarah from Marketing today about the new product launch. We discussed the timeline and decided to set the launch date for July 15th. After work, I went to Central Park for a quick run to clear my head. Feeling excited about the upcoming TechConf 2023 in San Francisco next month."
  );
  const [extractedEntities, setExtractedEntities] = useState([]);
  const [extractedData, setExtractedData] = useState(null);
  const [message, setMessage] = useState('');
  const [recentExtractions, setRecentExtractions] = useState([]);

  const { isLoading, error, makeApiCall } = useApiCall();

  const getEntityIcon = (type) => {
    switch (type.toLowerCase()) {
      case 'person':
        return <User className="text-blue-500" />;
      case 'event':
        return <Calendar className="text-green-500" />;
      case 'place':
        return <MapPin className="text-yellow-500" />;
      default:
        return <FileArchive className="text-purple-500" />;
    }
  };


  useEffect(() => {
    // Simulated API call for recent extractions
    setRecentExtractions([
      { source: 'LinkedIn', date: '2023-06-01', entityCount: 5 },
      { source: 'Calendar', date: '2023-05-28', entityCount: 3 },
      { source: 'Manual Input', date: '2023-05-25', entityCount: 2 },
      { source: 'Jurni Chat', date: '2023-05-20', entityCount: 4 },
    ]);
  }, []);


  const handleExtract = async () => {
    try {
      const response = await makeApiCall('/extract-entities', 'POST', { text: inputText });
      if (response) {
        setExtractedData(response);
        setExtractedEntities(response.entities.map((entity, index) => ({
          ...entity,
          id: index + 1,
          icon: getEntityIcon(entity.type)
        })));
      } else {
        setExtractedEntities([]);
        setExtractedData(null);
      }
    } catch (error) {
      console.error("Error extracting entities:", error);
      setExtractedEntities([]);
      setExtractedData(null);
    }
  };

  const handleSaveEntities = async () => {
    if (!extractedData) {
      setMessage("No extracted data to save");
      return;
    }
    try {
      const response = await makeApiCall('/save-entities', 'POST', extractedData);
      if (response) {
        setExtractedEntities([]);
        setExtractedData(null);
        setMessage("Entities and relations saved successfully");
      }
    } catch (error) {
      console.error("Error saving entities:", error);
    }
  };


  // const handleExtract = async () => {
  //   // Simulated API call for entity extraction
  //   const sampleEntities = [
  //     {
  //       id: 1,
  //       name: "Sarah",
  //       type: "Person",
  //       bio: "Colleague from Marketing department",
  //       tags: ["colleague", "marketing"],
  //       impact: 75,
  //       connections: 3,
  //       icon: <User className="text-blue-500" />,
  //     },
  //     {
  //       id: 2,
  //       name: "Product Launch",
  //       type: "Event",
  //       bio: "New product launch scheduled for July 15th",
  //       tags: ["work", "milestone"],
  //       impact: 90,
  //       connections: 5,
  //       icon: <Calendar className="text-green-500" />,
  //     },
  //     {
  //       id: 3,
  //       name: "Central Park",
  //       type: "Place",
  //       bio: "Public park in New York City, visited for a run",
  //       tags: ["exercise", "outdoors"],
  //       impact: 60,
  //       connections: 2,
  //       icon: <MessageSquare className="text-yellow-500" />,
  //     },
  //     {
  //       id: 4,
  //       name: "TechConf 2023",
  //       type: "Event",
  //       bio: "Upcoming tech conference in San Francisco",
  //       tags: ["professional", "networking"],
  //       impact: 85,
  //       connections: 4,
  //       icon: <Calendar className="text-purple-500" />,
  //     },
  //   ];
  //   setExtractedEntities(sampleEntities);
  // };
  // const handleSaveEntities = async () => {
  //   // Implement save functionality
  //   console.log("Saving entities:", extractedEntities);
  // };

  const handleEditEntity = (entity) => {
    // Implement edit functionality
    console.log("Editing entity:", entity);
  };

  const handleDeleteEntity = (entityId) => {
    setExtractedEntities(extractedEntities.filter(e => e.id !== entityId));
  };



  return (
    <Layout>
      <div className="max-w-6xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-white mb-6">DataHub</h1>
        <MessageDisplay message={error} type="error" />
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-8">
          <DataSourceButton icon={File} label="Upload File" onClick={() => setInputMethod('file')} />
          <DataSourceButton icon={Clipboard} label="Paste Text" onClick={() => setInputMethod('paste')} />
          <DataSourceButton icon={MessageSquare} label="Jurni Chat" onClick={() => setInputMethod('chat')} />
          <DataSourceButton icon={Plus} label="Connect Apps" onClick={() => navigate('/data-wizard')} />
        </div>

        {inputMethod === 'paste' && (
          <div className="mb-8">
            <textarea
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="Paste your text here..."
              className="w-full h-40 p-4 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
            <Button
              onClick={handleExtract}
              isLoading={isLoading}
              className="mt-4 bg-purple-600 text-white"
            >
              Extract Entities
            </Button>
          </div>
        )}

        {extractedEntities.length > 0 && (
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-white mb-4">Extracted Entities</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <AnimatePresence>
                {extractedEntities.map(entity => (
                  <SmartCard
                    key={entity.id}
                    entity={entity}
                    onEdit={handleEditEntity}
                    onDelete={handleDeleteEntity}
                  />
                ))}
              </AnimatePresence>
            </div>
            <Button
              onClick={handleSaveEntities}
              isLoading={isLoading}
              className="mt-4 bg-green-600 text-white"
            >
              Save to Graph
            </Button>
          </div>
        )}

        <div>
          <h2 className="text-2xl font-semibold text-white mb-4">Recent Extractions</h2>
          <div className="bg-gray-800 rounded-lg p-4">
            {recentExtractions.map((extraction, index) => (
              <div key={index} className="flex items-center justify-between py-2 border-b border-gray-700 last:border-b-0">
                <span className="text-gray-300">{extraction.source}</span>
                <span className="text-sm text-gray-400">{extraction.date} ({extraction.entityCount} entities)</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DataHub;