import React, { useEffect, useState } from "react";
import { useApi } from '../callApi';
import Dropzone from 'react-dropzone';
import { useLoading } from "../components/useLoading";
import { useNavigate } from "react-router-dom";
import { User, Calendar, Briefcase, FileText, Plus } from 'lucide-react';

const AppCard = ({ app, onConnect, isLoading }) => {
  const getIcon = (appName) => {
    switch (appName.toLowerCase()) {
      case 'linkedin': return <Briefcase className="w-6 h-6 text-blue-500" />;
      case 'google calendar': return <Calendar className="w-6 h-6 text-green-500" />;
      case 'facebook': return <User className="w-6 h-6 text-blue-600" />;
      default: return <FileText className="w-6 h-6 text-purple-500" />;
    }
  };

  return (
    <div className="bg-gray-800 rounded-lg p-4 hover:bg-gray-750 transition-all duration-300">
      <div className="flex items-center mb-4">
        <div className="mr-4 p-2 bg-gray-700 rounded-full">
          {getIcon(app.name)}
        </div>
        <div>
          <h3 className="text-lg font-semibold text-white">{app.name}</h3>
          <p className="text-sm text-gray-400">{app.description || 'Connect your account'}</p>
        </div>
      </div>
      <button
        onClick={() => onConnect(app)}
        className="w-full bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition-colors duration-300 flex items-center justify-center"
        disabled={isLoading}
      >
        {isLoading ? (
          <span className="loader"></span>
        ) : (
          <>
            <Plus className="w-5 h-5 mr-2" />
            Connect
          </>
        )}
      </button>
    </div>
  );
};

export default function AppList() {
  const [apps, setApps] = useState([]);
  const { load: loadImportData, isLoading: isLoadingImportData } = useLoading();
  const { callApi, uploadFile } = useApi();
  const navigate = useNavigate();

  const handleConnect = async (app) => {
    if (app.type === 'file') {
      // Open file picker
      document.getElementById(`file-upload-${app.id}`).click();
    } else {
      // Redirect to OAuth flow
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}/connect/authorize?auth=${app.auth}&app=${app.id}&action=${app.action}`;
    }
  };

  const handleFileUpload = async (acceptedFiles, app) => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);

    try {
      await loadImportData(uploadFile(
        "/apps/upload",
        formData,
        { app: app.id, action: app.action }
      ));
      navigate('/lifegraph/viewer');
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };

  const fetchApps = async () => {
    try {
      const data = await callApi(`/apps`);
      setApps(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchApps();
  }, []);

  return (

    <div className="max-w-6xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-white mb-2">Connect Your Apps</h1>
      <p className="text-gray-400 mb-8">Integrate your accounts to enhance your life map</p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {apps.map((app) => (
          <React.Fragment key={app.id}>
            <AppCard
              app={app}
              onConnect={handleConnect}
              isLoading={isLoadingImportData}
            />
            {app.type === 'file' && (
              <Dropzone onDrop={(files) => handleFileUpload(files, app)}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={{ display: 'none' }}>
                    <input {...getInputProps()} id={`file-upload-${app.id}`} />
                  </div>
                )}
              </Dropzone>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>

  );
}