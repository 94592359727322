import React, { useState, useEffect } from 'react';
import { Search, Mic, Loader, Compass, DatabaseZap, BookOpen, Settings, User, Network, BarChart, Calendar, Briefcase, MapPin, Book, Zap, HelpCircle, LayoutDashboard, Menu, X, TreesIcon, LineChart, MessageCircle, DatabaseZapIcon, Lightbulb, LayoutDashboardIcon } from 'lucide-react';
import { useAuth0 } from "@auth0/auth0-react";
import Agent from '../Agent'; // Make sure to import the Agent component
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
const AIState = {
  IDLE: 'idle',
  LISTENING: 'listening',
  PROCESSING: 'processing',
  SPEAKING: 'speaking',
  STOPPED: 'stopped'
};
const SearchResult = ({ icon, title, subtitle, type }) => (
  <Link to="/cards/1">

    <div className="flex items-center p-2 hover:bg-gray-700 rounded cursor-pointer">
      {icon}
      <div className="ml-3">
        <p className="text-sm font-medium text-white">{title}</p>
        <p className="text-xs text-gray-400">{subtitle}</p>
      </div>
      <span className="ml-auto text-xs text-gray-500">{type}</span>
    </div>
  </Link>
);

const GlobalSearch = ({ isOpen, onClose }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        onClose(!isOpen);
      } else if (event.key === 'Escape') {
        onClose(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, onClose]);

  useEffect(() => {
    // Simulated search results - replace with actual search logic
    if (searchQuery) {
      setSearchResults([
        { icon: <User size={18} className="text-blue-500" />, title: "Sarah Johnson", subtitle: "Last contact: 2 days ago", type: "Person" },
        { icon: <Calendar size={18} className="text-yellow-500" />, title: "Team Meeting", subtitle: "Tomorrow at 10:00 AM", type: "Event" },
        { icon: <Briefcase size={18} className="text-purple-500" />, title: "Project Deadline", subtitle: "Due next week", type: "Work" },
        { icon: <MapPin size={18} className="text-red-500" />, title: "Central Park", subtitle: "Last visited: 1 week ago", type: "Place" },
        { icon: <Book size={18} className="text-green-500" />, title: "Journal Entry", subtitle: "Feeling grateful for...", type: "Journal" },
        { icon: <Zap size={18} className="text-orange-500" />, title: "Exercise Goal", subtitle: "30 minutes daily", type: "Goal" },
      ]);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center pt-20 z-50">
      <div className="bg-gray-800 w-full max-w-2xl rounded-lg shadow-lg overflow-hidden">
        <div className="p-4 border-b border-gray-700 flex items-center">
          <Search className="text-gray-400 mr-2" />
          <input
            type="text"
            placeholder="Search your life..."
            className="bg-transparent text-white w-full focus:outline-none text-lg"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            autoFocus
          />
          <button onClick={() => onClose(false)} className="text-gray-400 hover:text-white">
            <X size={20} />
          </button>
        </div>
        <div className="max-h-96 overflow-y-auto">
          {searchResults.map((result, index) => (
            <SearchResult key={index} {...result} />
          ))}
        </div>
      </div>
    </div>
  );
};


const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [aiState, setAIState] = useState(AIState.IDLE);

  const [agentModalOpen, setAgentModalOpen] = useState(false);
  const toggleAIInteraction = () => {
    if (aiState === AIState.IDLE || aiState === AIState.STOPPED) {
      setAIState(AIState.LISTENING);
    } else if (aiState === AIState.LISTENING) {
      setAIState(AIState.IDLE);
    }
    else if (aiState === AIState.SPEAKING) {
      setAIState(AIState.STOPPED);

    }
    // Do nothing if processing or speaking, as these should be handled by the Agent
  };
  const getButtonStyles = () => {
    switch (aiState) {
      case AIState.LISTENING:
        return 'bg-red-600 hover:bg-red-700';
      case AIState.PROCESSING:
        return 'bg-yellow-600 hover:bg-yellow-700';
      case AIState.SPEAKING:
        return 'bg-green-600 hover:bg-green-700';
      default:
        return 'bg-purple-600 hover:bg-purple-700';
    }
  };
  // Function to get button icon based on AI state
  const getButtonIcon = () => {
    switch (aiState) {
      case AIState.LISTENING:
        return <Mic size={24} className="text-white" />;
      case AIState.PROCESSING:
        return <Loader size={24} className="text-white animate-spin" />;
      case AIState.SPEAKING:
        return <MessageCircle size={24} className="text-white" />;
      default:
        return <Mic size={24} className="text-white" />;
    }
  };

  const openAgentModal = () => {
    setAgentModalOpen(true);
  };
  const { user } = useAuth0();

  return (

    <div className="flex flex-col h-screen bg-gray-900 text-gray-200">
      {/* Mobile header */}
      <header className="lg:hidden flex justify-between items-center p-4 bg-gray-800">
        <span className="text-xl font-semibold text-white">Jurni</span>
        <button onClick={() => setSidebarOpen(!sidebarOpen)} className="text-white">
          {sidebarOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </header>

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <aside className={`w-64 flex-shrink-0 p-4 bg-gray-900 border-r border-gray-800 overflow-y-auto
                          ${sidebarOpen ? 'block' : 'hidden'} lg:block
                          fixed inset-y-0 left-0 z-50 lg:relative lg:translate-x-0 transition-transform duration-300 ease-in-out
                          ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}>
          <div className="flex flex-col h-full">
            <div className="hidden lg:flex items-center mb-6">
              <span className="text-xl font-semibold text-white">Jurni</span>
            </div>

            <Link to="/reflect">
              <button className="w-full py-2 px-4 mb-4 bg-purple-600 hover:bg-purple-700 rounded-full flex items-center justify-center text-white font-semibold transition-colors">
                <span>Ask  Jurni</span>
                <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" /></svg>
              </button>
            </Link>
            <nav className="mb-6">
              <ul className="space-y-2">
                <li><Link to="/dashboard" className="flex items-center hover:bg-gray-800 p-2 rounded-md"><LayoutDashboardIcon className="mr-2" size={18} /> Life Insights</Link></li>
                <li><Link to="/cards" className="flex items-center hover:bg-gray-800 p-2 rounded-md"><BookOpen className="mr-2" size={18} /> Library</Link></li>
                <li><Link to="/life-graph" className="flex items-center hover:bg-gray-800 p-2 rounded-md"><Network className="mr-2" size={18} /> Life Graph</Link></li>
                <li><Link to="/data-hub" className="flex items-center hover:bg-gray-800 p-2 rounded-md"><DatabaseZap className="mr-2" size={18} /> Data Hub</Link></li>
              </ul>
            </nav>

            {/* <nav className="mb-6">
              <ul className="space-y-2">
                <li><a href="/dashboard" className="flex items-center hover:bg-gray-800 p-2 rounded-md"><Lightbulb className="mr-2" size={18} /> Insights</a></li>
                <li><a href="/life-graph" className="flex items-center hover:bg-gray-800 p-2 rounded-md"><MessageCircle className="mr-2" size={18} /> Ask</a></li>
                <li><a href="/cards" className="flex items-center hover:bg-gray-800 p-2 rounded-md"><BookOpen className="mr-2" size={18} /> Cards</a></li>
                <li><a href="/data-hub" className="flex items-center hover:bg-gray-800 p-2 rounded-md"><DatabaseZapIcon className="mr-2" size={18} /> Data Hub</a></li>

               <li><a href="#" className="flex items-center hover:bg-gray-800 p-2 rounded-md"><Compass className="mr-2" size={18} /> Discover</a></li>
                <li><a href="#" className="flex items-center hover:bg-gray-800 p-2 rounded-md"><BookOpen className="mr-2" size={18} /> Library</a></li> 
                <li><a href="/life-graph" className="flex items-center hover:bg-gray-800 p-2 rounded-md"><LineChart className="mr-2" size={18} /> Visualize</a></li> 

              </ul>
            </nav> */}

            <div className="mt-4">
              <h3 className="text-sm font-semibold mb-3 text-gray-400">Recent Entries</h3>
              <ul className="text-sm space-y-3">
                <li className="truncate hover:bg-gray-800 p-2 rounded-md cursor-pointer">Today's reflection</li>
                <li className="truncate hover:bg-gray-800 p-2 rounded-md cursor-pointer">Weekly goals</li>
                <li className="truncate hover:bg-gray-800 p-2 rounded-md cursor-pointer">Gratitude list</li>
                {/* <li className="truncate hover:bg-gray-800 p-2 rounded-md cursor-pointer">Personal insights</li>
                <li className="truncate hover:bg-gray-800 p-2 rounded-md cursor-pointer">Monthly review</li> */}
              </ul>
            </div>

            <div className="mt-auto pt-6">
              <div className="mb-4">
                <h4 className="text-sm font-semibold mb-2 text-gray-400">Upgrade to Pro</h4>
                <p className="text-xs text-gray-500">Get advanced AI insights and more.</p>
                <a href="#" className="text-xs text-purple-400 hover:text-purple-300">Learn More</a>
              </div>

              <div className="flex items-center">
                <img src={user.picture} alt="User avatar" className="w-8 h-8 rounded-full mr-2" />
                <span>{user.name}</span>
                <Settings size={18} className="ml-auto text-gray-400" />
              </div>
            </div>
          </div>
        </aside>

        {/* Main content */}
        <div className="flex-1 overflow-x-hidden overflow-y-auto">
          <main className="max-w-6xl mx-auto px-4 py-8 lg:px-8">
            <button
              onClick={() => setIsSearchOpen(true)}
              className="fixed z-50 top-4 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-4 py-2 rounded-full shadow-lg hover:bg-gray-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
              <Search className="inline-block mr-2" size={18} />
              Search your life (⌘K)
            </button>

            <GlobalSearch isOpen={isSearchOpen} onClose={setIsSearchOpen} />

            {children}
          </main>

        </div>
      </div>

      {/* Footer */}
      <footer className="w-full py-4 bg-gray-900 border-t border-gray-800 mt-auto">
        <div className="flex flex-wrap justify-center space-x-4 px-4">
          <a href="#" className="text-sm text-gray-400 hover:text-white">Pro</a>
          <a href="#" className="text-sm text-gray-400 hover:text-white">Enterprise</a>
          <a href="#" className="text-sm text-gray-400 hover:text-white">Blog</a>
          <a href="#" className="text-sm text-gray-400 hover:text-white">Help Center</a>
          <div className="flex items-center text-gray-400">
            <span className="text-sm mr-1">English (English)</span>
            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" /></svg>
          </div>
        </div>
      </footer>

      {/* <motion.button
        onClick={openAgentModal}
        className="fixed bottom-4 right-20 p-3 bg-blue-600 hover:bg-blue-700 rounded-full shadow-lg transition-colors z-50"
      >
        <MessageCircle size={24} className="text-white" />
      </motion.button> */}

      {/* AI Interaction Button */}
      <motion.button
        onClick={toggleAIInteraction}
        className={`fixed bottom-4 right-4 p-3 rounded-full shadow-lg transition-colors z-50 ${getButtonStyles()}`}
        animate={{
          scale: aiState !== AIState.IDLE && aiState !== AIState.STOPPED ? [1, 1.1, 1] : 1,
        }}
        transition={{
          duration: 1,
          repeat: aiState !== AIState.IDLE && aiState !== AIState.STOPPED ? Infinity : 0,
          repeatType: "reverse",
        }}
      >
        {getButtonIcon()}
      </motion.button>


      {/* Agent Modal */}
      <AnimatePresence>
        {agentModalOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          >
            <div className="bg-gray-900 rounded-lg shadow-xl w-full max-w-2xl h-[80vh] relative overflow-hidden">
              <button
                onClick={() => setAgentModalOpen(false)}
                className="absolute top-2 right-2 text-gray-400 hover:text-white"
              >
                <X size={24} />
              </button>
              <Agent
                aiState={aiState}
                setAIState={setAIState}
                isModal={true}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Background Agent (when modal is closed) */}
      {aiState !== AIState.IDLE && !agentModalOpen && (
        <div className="sr-only">
          <Agent
            aiState={aiState}
            setAIState={setAIState}
            isModal={false}
          />
        </div>
      )}
    </div>

  );
};

export default Layout;