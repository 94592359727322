import React, { useState, useRef, useEffect } from 'react';
import { Send, User, Bot } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import SmartCard from './SmartCard';
import { useApi } from '../callApi';

// ChatMessage component remains the same
const ChatMessage = ({ message, isUser }) => (
  <div className={`flex ${isUser ? 'justify-end' : 'justify-start'} mb-4`}>
    <div className={`flex items-start max-w-3xl ${isUser ? 'flex-row-reverse' : 'flex-row'}`}>
      <div className={`flex-shrink-0 ${isUser ? 'ml-3' : 'mr-3'}`}>
        {isUser ? (
          <User className="w-8 h-8 rounded-full bg-purple-600 p-1 text-white" />
        ) : (
          <Bot className="w-8 h-8 rounded-full bg-blue-600 p-1 text-white" />
        )}
      </div>
      <div className={`flex flex-col ${isUser ? 'items-end' : 'items-start'}`}>
        {message.type === 'text' ? (
          <div className={`px-4 py-2 rounded-lg ${isUser ? 'bg-purple-600 text-white' : 'bg-gray-700 text-gray-200'}`}>
            <p>{message.content}</p>
          </div>
        ) : (
          <SmartCard entity={message.content} />
        )}
      </div>
    </div>
  </div>
);

const Chat = ({ initialInput = '' }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState(initialInput);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);
  const { callApiStream } = useApi();

  useEffect(() => {
    setInput(initialInput);
    if (initialInput) {
      handleSend(initialInput);
    }
  }, [initialInput]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSend = async (messageToSend) => {
    const message = messageToSend || input;
    if (message.trim() === '') return;

    const userMessage = { type: 'text', content: message, isUser: true };
    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsTyping(true);

    try {
      const response = await callApiStream('/chat', { method: 'POST' }, { message });
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let botMessage = { type: 'text', content: '', isUser: false };
      setMessages(prev => [...prev, botMessage]);

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const content = line.slice(6);
            if (content === '[DONE]') {
              setIsTyping(false);
              break;
            }
            botMessage.content += content;
            setMessages(prevMessages => [
              ...prevMessages.slice(0, -1),
              { ...botMessage }
            ]);
          }
        }
      }
    } catch (error) {
      console.error("Error in chat:", error);
      const errorMessage = { type: 'text', content: "Sorry, I couldn't process your request. Please try again.", isUser: false };
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  return (
    <div className="flex flex-col h-full bg-gray-900">
      <div className="flex-1 overflow-y-auto p-4">
        <AnimatePresence>
          {messages.map((message, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <ChatMessage message={message} isUser={message.isUser} />
            </motion.div>
          ))}
        </AnimatePresence>
        {isTyping && (
          <div className="flex justify-start mb-4">
            <div className="bg-gray-700 text-white px-4 py-2 rounded-lg">
              Jurni is thinking...
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="border-t border-gray-700 p-4">
        <div className="flex items-center max-w-4xl mx-auto">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            placeholder="Ask Jurni anything about your life..."
            className="flex-1 bg-gray-800 text-white placeholder-gray-400 rounded-l-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-purple-600"
          />
          <button
            onClick={() => handleSend()}
            className="bg-purple-600 text-white rounded-r-lg px-4 py-3 hover:bg-purple-700 transition-colors focus:outline-none focus:ring-2 focus:ring-purple-600"
          >
            <Send size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;