import React, { useState, useRef, useEffect } from 'react';
import { Send, User, Bot, Heart, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
// SmartCard component
const SmartCard = ({ entity, onClose }) => (
  <motion.div
    layout
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.3 }}
    className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg p-4 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-700 max-w-sm"
  >
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center">
        <div className="p-2 bg-gray-700 rounded-full">{entity.icon}</div>
        <h3 className="text-lg font-semibold ml-3 text-white">{entity.name}</h3>
      </div>
      <span className="text-xs font-medium px-2 py-1 bg-purple-600 text-white rounded-full">
        {entity.type}
      </span>
    </div>
    <p className="text-sm text-gray-300 mb-4">{entity.bio || entity.description}</p>
    <div className="flex flex-wrap gap-2 mb-4">
      {entity.tags.map((tag, index) => (
        <span key={index} className="text-xs bg-gray-700 text-gray-300 px-2 py-1 rounded-full">
          #{tag}
        </span>
      ))}
    </div>
    <div className="flex justify-between text-sm font-medium">
      <div className="flex items-center">
        <Heart size={16} className="text-red-500 mr-1" />
        <span className="text-gray-300">Impact: </span>
        <span className="text-white ml-1">{entity.impact}%</span>
      </div>
      <div className="flex items-center">
        <User size={16} className="text-blue-500 mr-1" />
        <span className="text-gray-300">Connections: </span>
        <span className="text-white ml-1">{entity.connections}</span>
      </div>
    </div>
    {onClose && (
      <button onClick={onClose} className="absolute top-2 right-2 text-gray-400 hover:text-white">
        <X size={20} />
      </button>
    )}
  </motion.div>
);

export default SmartCard