import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ArrowRight, Brain, BookOpen, Calendar, Zap, Share2, MessageSquare } from "lucide-react";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { ForceGraph3D } from 'react-force-graph';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  const [activeSection, setActiveSection] = useState(0);
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const [insightData, setInsightData] = useState([]);

  useEffect(() => {
    // Generate demo graph data
    const nodes = Array.from({ length: 50 }, (_, i) => ({
      id: i,
      name: `Node ${i}`,
      val: Math.random() * 20
    }));
    const links = Array.from({ length: 80 }, () => ({
      source: Math.floor(Math.random() * nodes.length),
      target: Math.floor(Math.random() * nodes.length)
    }));
    setGraphData({ nodes, links });

    // Generate demo insight data
    const insights = Array.from({ length: 30 }, (_, i) => ({
      date: `2024-${String(i + 1).padStart(2, '0')}-01`,
      happiness: Math.random() * 100,
      productivity: Math.random() * 100,
      relationships: Math.random() * 100
    }));
    setInsightData(insights);
  }, []);

  const features = [
    { icon: BookOpen, title: "Intelligent Journaling", description: "Reflect, analyze, and grow with an AI companion that understands your journey." },
    { icon: Brain, title: "Personalized Insights", description: "Gain deep understanding of your thoughts, patterns, and personal growth over time." },
    { icon: Share2, title: "Seamless Integration", description: "Connect your social media, notes, and apps for a comprehensive life overview." },
    { icon: Calendar, title: "Memory Timeline", description: "Visualize your past experiences and future possibilities in an interactive timeline." },
    { icon: Zap, title: "Real-time Reflection", description: "Get instant feedback and prompts to deepen your self-awareness and personal growth." },
    { icon: MessageSquare, title: "Natural Conversations", description: "Chat with Jurni about your day, ideas, or concerns - just like talking to a friend." }
  ];

  const ActionButton = ({ children, className }) => (
    <Link to="/dashboard" className={`${className} inline-flex items-center justify-center`}>
      {children}
      <ArrowRight className="ml-2" />
    </Link>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-900 via-purple-900 to-pink-800 text-white">
      <nav className="container mx-auto px-6 py-6 flex justify-between items-center">
        <div className="text-2xl font-bold">Jurni</div>
        <ActionButton className="bg-white text-purple-800 px-4 py-2 rounded-full text-sm font-semibold hover:bg-purple-100 transition duration-300">
          Start Your Jurni
        </ActionButton>
      </nav>

      <div className="container mx-auto px-6 py-20">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h1 className="text-6xl font-bold mb-6">Your AI-Powered Life Journal</h1>
          <p className="text-xl mb-10">Reflect, connect, and grow with a companion that truly understands you</p>
          <ActionButton className="bg-gradient-to-r from-pink-500 to-purple-600 text-white font-bold py-3 px-8 rounded-full text-lg shadow-lg hover:from-pink-600 hover:to-purple-700 transition duration-300">
            Begin Your Journey
          </ActionButton>

        </motion.div>

        <div className="grid md:grid-cols-3 gap-8 mb-20">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.1 }}
              className="bg-white bg-opacity-10 rounded-lg p-6 backdrop-filter backdrop-blur-lg hover:bg-opacity-20 transition duration-300"
            >
              <feature.icon className="w-12 h-12 text-pink-400 mb-4" />
              <h2 className="text-2xl font-semibold mb-2">{feature.title}</h2>
              <p className="text-gray-300">{feature.description}</p>
            </motion.div>
          ))}
        </div>

        <div className="mb-20">
          <h2 className="text-4xl font-bold mb-10 text-center">Experience Jurni</h2>
          <div className="bg-white bg-opacity-10 rounded-lg overflow-hidden backdrop-filter backdrop-blur-lg">
            <div className="flex border-b border-gray-700">
              {["Daily Reflection", "Network Visualization", "Insights Dashboard"].map((section, index) => (
                <button
                  key={index}
                  className={`flex-1 py-4 px-6 focus:outline-none ${activeSection === index ? 'bg-purple-700' : 'hover:bg-white hover:bg-opacity-10'}`}
                  onClick={() => setActiveSection(index)}
                >
                  {section}
                </button>
              ))}
            </div>
            <div className="p-6">
              {activeSection === 0 && (
                <div className="text-center">
                  <h3 className="text-2xl font-semibold mb-4">Reflect on Your Day</h3>
                  <p className="mb-4">Have a natural conversation with Jurni about your experiences, thoughts, and feelings.</p>
                  <div className="bg-gray-800 rounded-lg p-4 max-w-2xl mx-auto">
                    <div className="mb-4 text-left">
                      <p className="bg-purple-600 inline-block rounded-lg px-4 py-2 mb-2">Hey Jurni, today was quite challenging at work.</p>
                      <p className="bg-blue-600 inline-block rounded-lg px-4 py-2 mb-2">I'm sorry to hear that. Can you tell me more about what made it challenging?</p>
                      <p className="bg-purple-600 inline-block rounded-lg px-4 py-2">Well, we had a big project deadline and things didn't go as smoothly as planned...</p>
                    </div>
                    <input type="text" placeholder="Type your message..." className="w-full bg-gray-700 rounded-full px-4 py-2" />
                  </div>
                </div>
              )}
              {activeSection === 1 && (
                <div className="text-center">
                  <h3 className="text-2xl font-semibold mb-4">Explore Your Network</h3>
                  <p className="mb-4">Visualize your connections and how they relate to different aspects of your life.</p>
                  <div style={{ height: '400px' }}>
                    <ForceGraph3D
                      graphData={graphData}
                      nodeLabel="name"
                      nodeColor={() => `hsl(${Math.random() * 360}, 70%, 50%)`}
                      linkColor={() => `rgba(255, 255, 255, ${Math.random() * 0.5 + 0.2})`}
                    />
                  </div>
                </div>
              )}
              {activeSection === 2 && (
                <div className="text-center">
                  <h3 className="text-2xl font-semibold mb-4">Personalized Growth Insights</h3>
                  <p className="mb-4">Track your personal growth metrics over time with AI-generated insights.</p>
                  <div style={{ height: '400px' }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={insightData}>
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Line type="monotone" dataKey="happiness" stroke="#8884d8" />
                        <Line type="monotone" dataKey="productivity" stroke="#82ca9d" />
                        <Line type="monotone" dataKey="relationships" stroke="#ffc658" />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white bg-opacity-10 rounded-lg p-8 backdrop-filter backdrop-blur-lg mb-20">
          <h2 className="text-3xl font-bold mb-6 text-center">Connect Your World to Jurni</h2>
          <p className="text-center mb-8">Integrate your digital life for a more comprehensive and insightful journaling experience</p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 justify-items-center">
            {['Facebook', 'Twitter', 'Instagram', 'Google Drive', 'Evernote', 'Spotify', 'Fitbit', 'Apple Health'].map((app, index) => (
              <div key={index} className="bg-white bg-opacity-20 rounded-full py-2 px-4 text-sm">
                {app}
              </div>
            ))}
          </div>
        </div>

        <div className="text-center">
          <h2 className="text-4xl font-bold mb-6">Start Your Personal Growth Journey Today</h2>
          <ActionButton className="bg-white text-purple-800 font-bold py-3 px-8 rounded-full text-lg shadow-lg hover:bg-purple-100 transition duration-300">
            Try Jurni Free for 30 Days
          </ActionButton>
        </div>
      </div>

      <footer className="bg-indigo-900 py-10 mt-20">
        <div className="container mx-auto px-6 text-center">
          <p>&copy; 2024 Jurni. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;