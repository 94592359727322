import React, { useState, useEffect, useRef } from 'react';
import { Camera, Info, Mic, Monitor, Square } from 'lucide-react';
import process from 'process';
import { useApi } from '../callApi';
const AIState = {
  IDLE: 'idle',
  LISTENING: 'listening',
  PROCESSING: 'processing',
  SPEAKING: 'speaking',
  STOPPED: 'stopped'

};

const Agent = ({ aiState, setAIState, isModal = false }) => {
  const [volume, setVolume] = useState(0);
  const [error, setError] = useState(null);
  const messages = [];
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);

  const [isCapturing, setIsCapturing] = useState(false);
  const [commentary, setCommentary] = useState('');
  const [userTranscript, setUserTranscript] = useState('');
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const cameraVideoRef = useRef(null);
  const screenVideoRef = useRef(null);
  const audioContext = useRef(null);
  const analyzer = useRef(null);
  const microphone = useRef(null);
  const animationFrame = useRef(null);
  const recognition = useRef(null);
  const OPENAI_KEY = process.env.REACT_APP_OPENAI_API_KEY;
  const audioPlayer = useRef(new Audio());

  const { callApi, uploadFile } = useApi();

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  const playAudio = (base64Audio) => {
    const audioBlob = new Blob([base64Audio], { type: 'audio/wav' });
    const audioUrl = URL.createObjectURL(audioBlob);
    audioPlayer.current.src = audioUrl;

    audioPlayer.current.onended = () => {
      console.log("Audio playback finished, starting listening.");
      setAIState(AIState.LISTENING);

      startListening();  // Automatically start listening after audio ends
    };

    audioPlayer.current.play();
  };


  const stopAudio = () => {
    setIsSpeaking(false)
    if (audioPlayer.current) {
      audioPlayer.current.pause();
      audioPlayer.current.currentTime = 0;  // Reset the playback position
    }
  };

  const openAIAudio = async (inputText) => {
    try {
      const response = await fetch('https://api.openai.com/v1/audio/speech', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${OPENAI_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'tts-1',
          input: inputText,
          voice: 'alloy',
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setAIState(AIState.SPEAKING);
      setIsSpeaking(true);

      const blob = await response.blob();
      recognition.current.stop();
      setIsListening(false);
      playAudio(blob);
    } catch (err) {
      setError('Failed to fetch audio from OpenAI.');
      console.error('Error fetching audio from OpenAI:', err);
      //setAIState(AIState.IDLE);

    }
  };


  useEffect(() => {


    if (aiState === AIState.LISTENING && !isListening) {
      startListening();
    }
  }, [isListening]);


  useEffect(() => {


    if (aiState === AIState.STOPPED && isSpeaking) {
      console.log('**STOP SPEAKING****')
      setAIState(AIState.LISTENING);

      startListening()

    }
  }, [aiState]);






  useEffect(() => {
    if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      recognition.current = new SpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.lang = 'en-US';

      recognition.current.onstart = () => {
        console.log('Speech recognition started');
      };

      recognition.current.onresult = (event) => {
        let interimTranscript = '';
        let finalTranscript = '';

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }

        setUserTranscript(finalTranscript);
        if (finalTranscript.length > 1) {
          captureFrame(finalTranscript);
          messages.push({ text: finalTranscript, user: 'ahmed' });
        }
      };

      recognition.current.onend = () => {
        console.log('Speech recognition stopped');
      };

      recognition.current.onerror = (event) => {
        console.error('SpeechRecognition error', event.error);
        setIsListening(false)
        setError('Speech recognition error');
      };
    } else {
      setError('Speech recognition not supported in this browser');
    }

    return () => {
      if (recognition.current) {
        recognition.current.stop();
        setIsListening(false);
      }
    };
  }, []);

  useEffect(() => {
    if (isCapturing) {
      startVideoCapture();
      startListening();
    } else {
      stopVideoCapture();
      stopListening();
    }
  }, [isCapturing]);

  useEffect(() => {
    if (isScreenSharing) {
      startScreenSharing();
    } else {
      stopScreenSharing();
    }
  }, [isScreenSharing]);

  const startListening = async () => {

    stopAudio();  // Stop any ongoing AI speech to avoid conflicts

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      analyzer.current = audioContext.current.createAnalyser();
      microphone.current = audioContext.current.createMediaStreamSource(mediaStream);
      microphone.current.connect(analyzer.current);
      analyzer.current.fftSize = 256;
      setError(null);
      animateCircle();
      setIsListening(true);
      recognition.current.start();

    } catch (error) {
      console.error('Error accessing microphone:', error);
      setError('Error accessing microphone');
      //setAIState(AIState.IDLE);

    }
  };

  const stopListening = () => {

    if (microphone.current) {
      microphone.current.disconnect();
    }
    if (audioContext.current) {
      audioContext.current.close();
    }
    if (recognition.current) {
      recognition.current.stop();
    }
    setVolume(0);
    setIsListening(false);
    if (animationFrame.current) {
      cancelAnimationFrame(animationFrame.current);
    }
  };

  const animateCircle = () => {
    const dataArray = new Uint8Array(analyzer.current.frequencyBinCount);
    analyzer.current.getByteFrequencyData(dataArray);
    const average = dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
    setVolume(Math.min(average / 128, 1));
    animationFrame.current = requestAnimationFrame(animateCircle);
  };

  const startVideoCapture = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      cameraVideoRef.current.srcObject = mediaStream;
      await cameraVideoRef.current.play();
    } catch (error) {
      console.error('Error accessing camera:', error);
      setError('Error accessing camera');
    }
  };

  const stopVideoCapture = () => {
    if (cameraVideoRef.current && cameraVideoRef.current.srcObject) {
      cameraVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
  };

  const startScreenSharing = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      screenVideoRef.current.srcObject = mediaStream;
      await screenVideoRef.current.play();
    } catch (error) {
      console.error('Error accessing screen sharing:', error);
      setError('Error accessing screen sharing');
    }
  };

  const stopScreenSharing = () => {
    if (screenVideoRef.current && screenVideoRef.current.srcObject) {
      screenVideoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
  };

  const captureFrame = async (userTranscript) => {
    setAIState(AIState.PROCESSING);

    // Combine the video frames from both camera and screen
    const cameraImage = await captureVideo(cameraVideoRef.current);
    const screenImage = await captureVideo(screenVideoRef.current);

    try {
      const response = await fetchOpenAI([cameraImage, screenImage], userTranscript);
      const data = response.message ? response.message : JSON.parse(response.choices[0].message.content);
      const message = data?.message || data;

      if (data?.input_text) {
        sendTypeActionToServer(data.input_text);
      }
      messages.push({ text: message, user: 'jurni-ai' });

      setCommentary(message);
      setAIState(AIState.SPEAKING);
      setIsSpeaking(true);

      speakResponse(message);
    } catch (error) {
      console.error('Error processing or fetching AI response:', error);
      setCommentary('Failed to fetch response from AI.');
      //setAIState(AIState.IDLE);

    }

    setUserTranscript('');
  };

  const captureVideo = videoRef => {
    return new Promise((resolve, reject) => {
      if (videoRef && videoRef.srcObject && videoRef.videoWidth > 0) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = videoRef.videoWidth;
        canvas.height = videoRef.videoHeight;
        context.drawImage(videoRef, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(async blob => {
          const image = await blobToBase64(blob);
          resolve(image)
        });
      } else {
        resolve(null);  // Handle no video source available
      }
    });
  };

  const fetchOpenAI = async (images, text) => {
    const response = await callApi('/chat/', {
      method: 'POST',
    }, { message: text, stream: false });
    return response
    // let vmessages = [
    //   { type: "text", text: `The user just spoken said: "${text}"; the image is of them now. Reply to them based on what you see and what they said` },
    // ];
    // images.forEach(image => {
    //   if (image) {
    //     vmessages.push({ type: "image_url", image_url: { url: `data:image/jpeg;base64,${image}` } });
    //   }
    // });

    // const response = await fetch('https://api.openai.com/v1/chat/completions', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${OPENAI_KEY}`
    //   },
    //   body: JSON.stringify({
    //     model: "gpt-4o",
    //     response_format: { type: "json_object" },
    //     messages: [
    //       {
    //         role: "system", content: `
    //         You are a friendly helpful assistant.
    //         Your name is Jurni.
    //         You can see the user's camera and screen.
    //         You can control the user's browser to assist filling forms.
    //         You must always return only a JSON object with two properties
    //         message: the message you want to speak to user, input_text: text you want to enter on screen when needed.
    //         Here's a history of you conversation so far:
    //         ${JSON.stringify(messages)}
    //         `
    //       },
    //       {
    //         role: "user", content: vmessages
    //       }
    //     ]
    //   })
    // });
    // return response.json();
  };

  const sendTypeActionToServer = async (text) => {
    try {
      const response = await fetch('http://localhost:3001/send-key', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: text }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      console.log('Action performed successfully');
    } catch (err) {
      setError('Failed to perform action');
      console.error(err);
    }
  };

  const speakResponse = (text) => {
    openAIAudio(text);
  };


  if (isModal) {

    return (
      <div className="flex flex-col items-center justify-between bg-gray-900 text-white p-4 h-full">

        <div className="flex-grow flex items-center justify-center relative w-full">
          <div className="absolute inset-0 flex items-center justify-center">
            <div
              className="bg-blue-500 rounded-full transition-all duration-100 ease-in-out"
              style={{
                width: `${32 + volume * 10}vmin`,
                height: `${32 + volume * 10}vmin`,
                opacity: 0.1 + volume * 0.5
              }}
            />
          </div>
        </div>
        <div className="w-full flex justify-center items-center space-x-4 mb-4">
          <button
            className={`w-12 h-12 rounded-full flex items-center justify-center transition-colors ${isCapturing ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'}`}
            onClick={() => setIsCapturing(!isCapturing)}
            aria-label={isCapturing ? 'Stop Video and Audio Capture' : 'Start Video and Audio Capture'}
          >
            <Camera size={24} />
          </button>
          <button
            className={`w-12 h-12 rounded-full flex items-center justify-center transition-colors ${isScreenSharing ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'}`}
            onClick={() => setIsScreenSharing(!isScreenSharing)}
            aria-label={isScreenSharing ? 'Stop Screen Sharing' : 'Start Screen Sharing'}
          >
            <Monitor size={24} />
          </button>
          <button
            className={`w-12 h-12 rounded-full flex items-center justify-center transition-colors ${isListening ? 'bg-red-500 hover:bg-red-600' : 'bg-gray-500 hover:bg-gray-600'}`}
            onClick={() => isListening ? stopListening() : startListening()}
            aria-label={isListening ? 'Stop Listening' : 'Start Listening'}
          >
            {isListening ? <Square size={24} /> : <Mic size={24} />}
          </button>
        </div>

        <div className="w-full space-y-4 mb-4">
          <div className="bg-gray-800 rounded-lg p-4 min-h-[60px] text-blue-400">
            {isListening ? 'Listening...' : ''}
            {userTranscript}
          </div>
          <div className="bg-gray-800 rounded-lg p-4 min-h-[60px] text-green-400">
            {commentary}
          </div>
        </div>



        {(isCapturing || isScreenSharing) && (
          <div className="absolute bottom-4 right-4 flex space-x-2">
            {isCapturing && (
              <div className="w-32 h-24 bg-gray-800 rounded-lg overflow-hidden">
                <video ref={cameraVideoRef} className="w-full h-full object-cover" />
              </div>
            )}
            {isScreenSharing && (
              <div className="w-32 h-24 bg-gray-800 rounded-lg overflow-hidden">
                <video ref={screenVideoRef} className="w-full h-full object-cover" />
              </div>
            )}
          </div>
        )}
      </div>
    );
  } else
    return null
};

export default Agent;
