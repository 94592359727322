import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, List, User, MessageCircle, Calendar, Tag, ArrowRight, Play, Pause } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import SmartCard from '../components/SmartCard';
import StatusMonitor from '../components/StatusMonitor';
import BackgroundAnimation from '../components/BackgroundAnimation';

const Teleprompter = ({ text, currentIndex }) => {
  const words = text.split(' ');

  return (
    <div className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold text-center min-h-[12rem] sm:min-h-[16rem] flex items-center justify-center overflow-hidden px-4">
      <div className="max-w-2xl">
        {words.map((word, index) => (
          <span
            key={index}
            className={`inline-block mx-1 transition-opacity duration-300 ${index === currentIndex ? 'opacity-100' : 'opacity-30'
              }`}
          >
            {word}
          </span>
        ))}
      </div>
    </div>
  );
};
const MediaPanel = ({ media }) => {
  if (!media || media.length === 0) return null;

  return (
    <div className="w-full h-full bg-purple-800 rounded-lg shadow-lg overflow-hidden">
      {media.map((item, index) => (
        <div key={index} className="relative">
          {item.type === 'image' ? (
            <img src={item.url} alt={item.caption} className="w-full h-full object-cover" />
          ) : item.type === 'video' ? (
            <video src={item.url} controls className="w-full h-full object-cover" />
          ) : null}
          {item.caption && (
            <p className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-sm">
              {item.caption}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

const ReflectionSummary = () => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const [showLayout, setShowLayout] = useState(false);
  const [status, setStatus] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isAudioReady, setIsAudioReady] = useState(false);
  const audioPlayer = useRef(new Audio());
  const OPENAI_KEY = process.env.REACT_APP_OPENAI_API_KEY;

  // Dummy data (same as before)
  const reflectionData = {
    title: "Q2 Strategy Meeting Reflection",
    sender: {
      name: "John Doe",
      avatar: "https://i.pravatar.cc/150?u=johndoe@example.com"
    },
    recipient: "Sarah",
    date: "2023-07-18",
    topic: "Quarterly Strategy Review",
    summaryText: "Hi Sarah, I wanted to share my thoughts on our Q2 strategy meeting. We tackled challenges in project timelines and resource allocation. I believe we need to improve our cross-team communication and implement more frequent check-ins for our long-term projects. Your insights on this would be valuable.",
    summaryPoints: [
      "Project timeline slippage is our major challenge",
      "We need to improve cross-team communication",
      "Implementing bi-weekly strategy check-ins could help",
      "More flexible resource allocation is necessary"
    ],
    talkingPoints: [
      "How do you think we can improve our project management?",
      "What challenges do you see in our cross-team communication?",
      "Would bi-weekly strategy check-ins be effective in your opinion?",
      "Which areas of our strategy do you feel need more attention?"
    ],
    media: [
      { type: 'image', url: 'https://images.pexels.com/photos/1181605/pexels-photo-1181605.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2', caption: 'Our team during the Q2 strategy meeting' },
    ]
  };

  const currentEntity = {
    name: "Strategic Planning",
    type: "Process",
    bio: "The ongoing process of defining strategy and planning for its implementation.",
    tags: ["project management", "resource allocation", "communication", "long-term planning"],
    impact: 90,
    connections: 7
  };


  const getAIAudioFromText = async (inputText) => {
    setStatus('loading');
    try {
      const response = await fetch('https://api.openai.com/v1/audio/speech', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${OPENAI_KEY}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'tts-1',
          input: inputText,
          voice: 'alloy',
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      setStatus(null);
      return blob;
    } catch (err) {
      setStatus('error');
      console.error('Error fetching audio from OpenAI:', err);
    }
  };

  useEffect(() => {
    const fetchAudio = async () => {
      const blob = await getAIAudioFromText(reflectionData.summaryText);
      if (blob) {
        setAudioBlob(blob);
        const audioUrl = URL.createObjectURL(blob);
        audioPlayer.current.src = audioUrl;
        audioPlayer.current.addEventListener('loadedmetadata', () => {
          setIsAudioReady(true);
        });
      }
    };

    fetchAudio();
  }, []);

  useEffect(() => {
    if (isPlaying) {
      audioPlayer.current.play();
    } else {
      audioPlayer.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (!isAudioReady) return;

    const words = reflectionData.summaryText.split(' ');
    const wordDuration = audioPlayer.current.duration / words.length;

    const updateIndex = () => {
      if (audioPlayer.current.currentTime >= audioPlayer.current.duration) {
        setIsPlaying(false);
        setCurrentIndex(0);
        return;
      }
      const newIndex = Math.floor(audioPlayer.current.currentTime / wordDuration);
      setCurrentIndex(newIndex);
    };

    const intervalId = setInterval(updateIndex, 50); // Update more frequently for smoother transitions

    return () => clearInterval(intervalId);
  }, [isAudioReady]);

  const togglePlayPause = () => {
    if (!isAudioReady) return;

    if (isPlaying) {
      audioPlayer.current.pause();
    } else {
      if (audioPlayer.current.currentTime >= audioPlayer.current.duration) {
        audioPlayer.current.currentTime = 0;
      }
      audioPlayer.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleLayout = () => {
    setShowLayout(!showLayout);
  };

  const handleReflectBack = () => {
    navigate(`/reflect/new?previousSession=${sessionId}`);
  };

  const sidePanel = (
    <AnimatePresence>
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: 0 }}
        exit={{ x: '100%' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        className="w-full md:w-96 bg-gray-800 p-6 overflow-y-auto"
      >
        <SmartCard entity={currentEntity} onClose={() => { }} />
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Key Points</h3>
          <ul className="list-disc list-inside text-sm text-gray-300">
            {reflectionData.summaryPoints?.map((point, index) => (
              <li key={index} className="mb-2">{point}</li>
            ))}
          </ul>
        </div>
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Questions for You</h3>
          {reflectionData.talkingPoints?.map((point, index) => (
            <div key={index} className="bg-purple-600 text-white p-2 rounded-lg mb-2">
              {point}
            </div>
          ))}
        </div>
      </motion.div>
    </AnimatePresence>
  );


  const mainContent = (
    <div className="flex-1 p-4 md:p-8 flex flex-col justify-center items-center relative">
      <BackgroundAnimation />
      <button
        onClick={() => setShowLayout(!showLayout)}
        className="absolute top-4 left-4 p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition-colors duration-200 z-10"
      >
        {showLayout ? <ChevronLeft className="w-6 h-6" /> : <List className="w-6 h-6" />}
      </button>
      <div className="w-full max-w-6xl text-center relative z-10">
        <div className="flex flex-col sm:flex-row items-center justify-center mb-6">
          <img src={reflectionData.sender.avatar} alt={reflectionData.sender.name} className="w-20 h-20 rounded-full mb-4 sm:mb-0 sm:mr-6" />
          <div className="text-center sm:text-left">
            <h2 className="text-2xl sm:text-3xl font-bold mb-2">{reflectionData.sender.name} shared a reflection with you</h2>
            <p className="text-base sm:text-lg text-gray-300">on your {reflectionData.title}</p>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-3 sm:space-y-0 sm:space-x-6 mb-6">
          <div className="flex items-center">
            <Calendar className="w-6 h-6 mr-2" />
            <span className="text-base sm:text-lg">{reflectionData.date}</span>
          </div>
          <div className="flex items-center">
            <Tag className="w-6 h-6 mr-2" />
            <span className="text-base sm:text-lg">{reflectionData.topic}</span>
          </div>
        </div>
        <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
          <div className="flex-1 order-1 md:order-2">
            <Teleprompter text={reflectionData.summaryText} currentIndex={currentIndex} />
          </div>
          <div className="w-full md:w-1/2 lg:w-2/5 order-2 md:order-1">
            <MediaPanel media={reflectionData.media} />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 mt-8">
          <button
            onClick={togglePlayPause}
            className={`bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center text-lg ${!isAudioReady ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!isAudioReady}
          >
            {isPlaying ? <Pause className="w-6 h-6 mr-2" /> : <Play className="w-6 h-6 mr-2" />}
            {isPlaying ? 'Pause' : 'Listen'}
          </button>
          <button
            onClick={handleReflectBack}
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center text-lg"
          >
            <MessageCircle className="w-6 h-6 mr-2" />
            Respond with Your Thoughts
          </button>
        </div>
        <p className="mt-6 text-sm sm:text-base text-gray-400">
          This reflection was shared via Jurni, a platform for meaningful conversations and personal growth.
        </p>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen w-full bg-purple-900 text-white overflow-hidden flex flex-col md:flex-row">
      {mainContent}
      {showLayout && sidePanel}
      <StatusMonitor status={status} />
    </div>
  );
};

export default ReflectionSummary;