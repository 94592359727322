
import React from 'react';
import Layout from '../components/shared/Layout';

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>404 - Not Found!</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  </Layout>

);

export default NotFoundPage;
