// components/MessageDisplay.js
import React from 'react';
import { AlertTriangle, Info } from 'lucide-react';

const MessageDisplay = ({ message, type = 'info' }) => {
  if (!message) return null;

  const isError = type === 'error';
  const Icon = isError ? AlertTriangle : Info;
  const bgColor = isError ? 'bg-red-100' : 'bg-blue-100';
  const textColor = isError ? 'text-red-700' : 'text-blue-700';
  const borderColor = isError ? 'border-red-400' : 'border-blue-400';

  return (
    <div className={`${bgColor} border ${borderColor} ${textColor} px-4 py-3 rounded relative`} role="alert">
      <Icon className="inline-block mr-2" size={18} />
      <span className="block sm:inline">{message}</span>
    </div>
  );
};

export default MessageDisplay;