import React from 'react';
import Layout from '../components/shared/Layout';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { MessageSquare, User, Calendar, Briefcase, MapPin, Heart, Book, Globe, Zap, TrendingUp, AlertTriangle, Lightbulb } from 'lucide-react';

const Card = ({ title, icon, children, className }) => (
  <div className={`bg-gray-800 rounded-lg p-4 shadow-lg ${className}`}>
    <div className="flex items-center mb-4">
      {icon}
      <h3 className="text-lg font-semibold ml-2">{title}</h3>
    </div>
    {children}
  </div>
);

const InsightCard = ({ icon, title, description, impact }) => (
  <div className="bg-gray-700 rounded-lg p-4 shadow-lg mb-4">
    <div className="flex items-center mb-2">
      {icon}
      <h4 className="text-md font-semibold ml-2">{title}</h4>
    </div>
    <p className="text-sm text-gray-300 mb-2">{description}</p>
    <p className="text-xs text-purple-300">Impact: {impact}</p>
  </div>
);

// Sample data - in a real application, this would come from your backend
const moodData = [
  { date: '2023-07-01', mood: 7 },
  { date: '2023-07-02', mood: 6 },
  { date: '2023-07-03', mood: 8 },
  { date: '2023-07-04', mood: 9 },
  { date: '2023-07-05', mood: 7 },
  { date: '2023-07-06', mood: 8 },
  { date: '2023-07-07', mood: 10 },
];

const Dashboard = () => {
  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-white">Your Life Insights</h1>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Main Insights Section */}
          <div className="lg:col-span-2 space-y-6">
            <Card title="Recent Insights" icon={<Lightbulb className="text-yellow-500" />} className="col-span-full">
              <div className="space-y-4">
                <InsightCard
                  icon={<TrendingUp className="text-green-500" />}
                  title="Productivity Boost"
                  description="Your productivity increases by 30% on days when you meditate in the morning. Consider making this a daily habit."
                  impact="High - Could significantly improve your work performance"
                />
                <InsightCard
                  icon={<User className="text-blue-500" />}
                  title="Social Connections"
                  description="Interactions with Alex and Jordan correlate with improved mood. You might want to prioritize time with them."
                  impact="Medium - Could contribute to better overall well-being"
                />
                <InsightCard
                  icon={<Briefcase className="text-purple-500" />}
                  title="Career Development"
                  description="You've been mentioning 'machine learning' more frequently. This could be a good area for professional development."
                  impact="High - Potential for career growth and new opportunities"
                />
                <InsightCard
                  icon={<AlertTriangle className="text-red-500" />}
                  title="Stress Pattern Detected"
                  description="Your journal entries show increased stress levels before team meetings. Consider discussing this with your manager."
                  impact="Medium - Addressing this could improve work satisfaction"
                />
              </div>
            </Card>

            <Card title="Predictive Insights" icon={<Globe className="text-indigo-500" />}>
              <ul className="space-y-2 text-sm">
                <li>Based on your current habits, you're likely to achieve your 'Read 24 books this year' goal.</li>
                <li>Your current spending patterns suggest you might exceed your budget next month. Consider reviewing your expenses.</li>
                <li>If you maintain your current exercise routine, you're on track to run a half-marathon in 3 months.</li>
              </ul>
            </Card>

            <Card title="Anomaly Detection" icon={<AlertTriangle className="text-orange-500" />}>
              <ul className="space-y-2 text-sm">
                <li>Your sleep pattern was irregular last week, coinciding with increased late-night social media usage.</li>
                <li>There's been a sudden drop in your physical activity this month. Is everything okay?</li>
                <li>Your sentiment towards work has become more negative recently. It might be time for a vacation or a chat with your manager.</li>
              </ul>
            </Card>
          </div>

          {/* Side Panel */}
          <div className="space-y-6">
            <Card title="Mood Trends" icon={<Heart className="text-pink-500" />}>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart data={moodData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="mood" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </Card>

            <Card title="Upcoming Events" icon={<Calendar className="text-yellow-500" />}>
              <ul className="space-y-2 text-sm">
                <li><span className="text-gray-400">2023-07-10:</span> Team meeting (Work)</li>
                <li><span className="text-gray-400">2023-07-15:</span> Sarah's birthday (Personal)</li>
                <li><span className="text-gray-400">2023-07-20:</span> Tech Conference (Professional)</li>
              </ul>
            </Card>

            <Card title="Habits & Goals" icon={<Zap className="text-green-500" />}>
              <ul className="space-y-2 text-sm">
                <li><span className="text-green-500">✓</span> Meditate for 10 minutes (7-day streak)</li>
                <li><span className="text-green-500">✓</span> Read 20 pages (5-day streak)</li>
                <li><span className="text-red-500">✗</span> Exercise for 30 minutes (2-day gap)</li>
              </ul>
            </Card>
          </div>
        </div>

        {/* Chat with Jurni */}
        <Card title="Chat with Jurni" icon={<MessageSquare className="text-blue-500" />} className="mt-6">
          <p className="text-sm text-gray-400 mb-2">Ask Jurni about your insights or for more detailed analysis.</p>
          <input
            type="text"
            placeholder="E.g., 'Can you elaborate on how my social interactions affect my productivity?'"
            className="w-full bg-gray-700 rounded-full px-4 py-2 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
          />
        </Card>
      </div>
    </Layout>
  );
};

export default Dashboard;