import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/shared/Layout';
import { useApi } from '../callApi';
import { motion } from 'framer-motion';
import { User, Heart, Calendar, MapPin, Briefcase, MessageSquare, Link, Clock, Send, Zap, TrendingUp, TrendingDown, Users, Phone, Mail, Globe, Camera } from 'lucide-react';
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Cell, ResponsiveContainer, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { ForceGraph2D } from 'react-force-graph';

const StatTile = ({ icon: Icon, title, value, trend }) => (
  <div className="bg-gray-800 rounded-lg p-4 flex items-center justify-between">
    <div className="flex items-center">
      <Icon size={24} className="text-purple-500 mr-3" />
      <div>
        <h3 className="text-sm text-gray-400">{title}</h3>
        <p className="text-2xl font-bold text-white">{value}</p>
      </div>
    </div>
    {trend && (
      <div className={`flex items-center ${trend > 0 ? 'text-green-500' : 'text-red-500'}`}>
        {trend > 0 ? <TrendingUp size={20} /> : <TrendingDown size={20} />}
        <span className="ml-1">{Math.abs(trend)}%</span>
      </div>
    )}
  </div>
);

const CardPage = () => {
  const { entityId } = useParams();
  const { callApi } = useApi();
  const [entityData, setEntityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');

  useEffect(() => {
    const fetchEntityData = async () => {
      try {
        // Simulated API call
        const data = {
          id: entityId,
          name: "Sarah Johnson",
          role: "Marketing Director",
          company: "TechCorp Inc.",
          bio: "Innovative marketing strategist with 10+ years of experience in tech industry.",
          avatar: "https://i.pravatar.cc/300",
          stats: {
            interactions: { value: 156, trend: 12 },
            impact: { value: 85, trend: 5 },
            connections: { value: 287, trend: -2 },
            events: { value: 34, trend: 8 },
          },
          contactInfo: {
            email: "sarah.johnson@techcorp.com",
            phone: "+1 (555) 123-4567",
            location: "San Francisco, CA",
          },
          interactionHistory: [
            { date: '2023-01', count: 10 },
            { date: '2023-02', count: 15 },
            { date: '2023-03', count: 8 },
            { date: '2023-04', count: 20 },
            { date: '2023-05', count: 12 },
            { date: '2023-06', count: 18 },
          ],
          connectionTypes: [
            { type: 'Professional', value: 150 },
            { type: 'Personal', value: 80 },
            { type: 'Industry', value: 57 },
          ],
          recentActivities: [
            { type: 'Meeting', description: 'Quarterly strategy review', date: '2023-06-15' },
            { type: 'Email', description: 'Campaign proposal sent', date: '2023-06-12' },
            { type: 'Call', description: 'Client follow-up', date: '2023-06-10' },
          ],
          skillsEndorsements: [
            { skill: 'Digital Marketing', endorsements: 78 },
            { skill: 'Team Leadership', endorsements: 64 },
            { skill: 'Strategy Development', endorsements: 59 },
            { skill: 'Data Analysis', endorsements: 45 },
          ],
          networkGraph: {
            nodes: [
              { id: 'sarah', name: 'Sarah Johnson', group: 1 },
              { id: 'techcorp', name: 'TechCorp Inc.', group: 2 },
              { id: 'marketing_team', name: 'Marketing Team', group: 3 },
              { id: 'john_doe', name: 'John Doe (CEO)', group: 2 },
              { id: 'jane_smith', name: 'Jane Smith (Client)', group: 4 },
              // Add more nodes as needed
            ],
            links: [
              { source: 'sarah', target: 'techcorp' },
              { source: 'sarah', target: 'marketing_team' },
              { source: 'sarah', target: 'john_doe' },
              { source: 'sarah', target: 'jane_smith' },
              // Add more links as needed
            ],
          },
        };
        setEntityData(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching entity data:', error);
        setLoading(false);
      }
    };

    fetchEntityData();
  }, [entityId]);

  const handleSendMessage = () => {
    if (chatInput.trim()) {
      setChatMessages([...chatMessages, { text: chatInput, sender: 'user' }]);
      setChatInput('');
      // Simulated AI response
      setTimeout(() => {
        setChatMessages(prev => [...prev, { text: `Here's some information about ${entityData.name} based on your query...`, sender: 'ai' }]);
      }, 1000);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!entityData) return <div>Entity not found</div>;

  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-gray-800 rounded-lg p-6 mb-8 flex items-center">
          <img src={entityData.avatar} alt={entityData.name} className="w-24 h-24 rounded-full mr-6" />
          <div>
            <h1 className="text-3xl font-bold text-white">{entityData.name}</h1>
            <p className="text-xl text-gray-300">{entityData.role} at {entityData.company}</p>
            <p className="text-gray-400 mt-2">{entityData.bio}</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <StatTile icon={Zap} title="Interactions" value={entityData.stats.interactions.value} trend={entityData.stats.interactions.trend} />
          <StatTile icon={Heart} title="Impact Score" value={`${entityData.stats.impact.value}%`} trend={entityData.stats.impact.trend} />
          <StatTile icon={Users} title="Connections" value={entityData.stats.connections.value} trend={entityData.stats.connections.trend} />
          <StatTile icon={Calendar} title="Events" value={entityData.stats.events.value} trend={entityData.stats.events.trend} />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2 space-y-8">
            <div className="bg-gray-800 rounded-lg p-6">
              <h2 className="text-xl font-semibold text-white mb-4">Interaction History</h2>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart data={entityData.interactionHistory}>
                  <XAxis dataKey="date" stroke="#9CA3AF" />
                  <YAxis stroke="#9CA3AF" />
                  <Tooltip />
                  <Line type="monotone" dataKey="count" stroke="#8B5CF6" strokeWidth={2} />
                </LineChart>
              </ResponsiveContainer>
            </div>

            <div className="bg-gray-800 rounded-lg p-6">
              <h2 className="text-xl font-semibold text-white mb-4">Connection Types</h2>
              <ResponsiveContainer width="100%" height={200}>
                <PieChart>
                  <Pie
                    data={entityData.connectionTypes}
                    dataKey="value"
                    nameKey="type"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8B5CF6"
                    label
                  >
                    {entityData.connectionTypes.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={['#8B5CF6', '#EC4899', '#F59E0B'][index % 3]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>

            <div className="bg-gray-800 rounded-lg p-6">
              <h2 className="text-xl font-semibold text-white mb-4">Skills & Endorsements</h2>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart data={entityData.skillsEndorsements}>
                  <XAxis dataKey="skill" stroke="#9CA3AF" />
                  <YAxis stroke="#9CA3AF" />
                  <Tooltip />
                  <Bar dataKey="endorsements" fill="#8B5CF6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="space-y-8">
            <div className="bg-gray-800 rounded-lg p-6">
              <h2 className="text-xl font-semibold text-white mb-4">Contact Information</h2>
              <div className="space-y-3">
                <div className="flex items-center">
                  <Mail className="text-purple-500 mr-2" size={20} />
                  <span className="text-gray-300">{entityData.contactInfo.email}</span>
                </div>
                <div className="flex items-center">
                  <Phone className="text-purple-500 mr-2" size={20} />
                  <span className="text-gray-300">{entityData.contactInfo.phone}</span>
                </div>
                <div className="flex items-center">
                  <MapPin className="text-purple-500 mr-2" size={20} />
                  <span className="text-gray-300">{entityData.contactInfo.location}</span>
                </div>
              </div>
            </div>

            <div className="bg-gray-800 rounded-lg p-6">
              <h2 className="text-xl font-semibold text-white mb-4">Recent Activities</h2>
              <div className="space-y-4">
                {entityData.recentActivities.map((activity, index) => (
                  <div key={index} className="flex items-start">
                    <div className="p-2 bg-gray-700 rounded-full mr-3">
                      {activity.type === 'Meeting' && <Users size={16} className="text-blue-500" />}
                      {activity.type === 'Email' && <Mail size={16} className="text-green-500" />}
                      {activity.type === 'Call' && <Phone size={16} className="text-yellow-500" />}
                    </div>
                    <div>
                      <p className="text-white">{activity.description}</p>
                      <p className="text-sm text-gray-400">{activity.date}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-gray-800 rounded-lg p-6">
              <h2 className="text-xl font-semibold text-white mb-4">Network Visualization</h2>
              <div style={{ height: '300px' }}>
                <ForceGraph2D
                  graphData={entityData.networkGraph}
                  nodeLabel="name"
                  nodeColor={node => node.id === 'sarah' ? '#8B5CF6' : '#3B82F6'}
                  linkColor={() => '#4B5563'}
                  nodeCanvasObject={(node, ctx, globalScale) => {
                    const label = node.name;
                    const fontSize = 12 / globalScale;
                    ctx.font = `${fontSize}px Sans-Serif`;
                    const textWidth = ctx.measureText(label).width;
                    const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2);

                    ctx.fillStyle = node.id === 'sarah' ? '#8B5CF6' : '#3B82F6';
                    ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);

                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.fillStyle = 'white';
                    ctx.fillText(label, node.x, node.y);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 bg-gray-800 rounded-lg p-6">
          <h2 className="text-xl font-semibold text-white mb-4">Chat with Jurni about {entityData.name}</h2>
          <div className="h-64 overflow-y-auto mb-4 space-y-2">
            {chatMessages.map((message, index) => (
              <div key={index} className={`p-2 rounded-lg ${message.sender === 'user' ? 'bg-purple-700 ml-auto' : 'bg-gray-700'} max-w-3/4`}>
                {message.text}
              </div>
            ))}
          </div>
          <div className="flex">
            <input
              type="text"
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              placeholder="Ask about this person..."
              className="flex-grow bg-gray-700 text-white rounded-l-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
            <button
              onClick={handleSendMessage}
              className="bg-purple-600 text-white rounded-r-lg px-4 py-2 hover:bg-purple-700 transition-colors"
            >
              <Send size={20} />
            </button>
          </div>
          <div className="flex">
            <input
              type="text"
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              placeholder="Ask about this person..."
              className="flex-grow bg-gray-700 text-white rounded-l-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
            <button
              onClick={handleSendMessage}
              className="bg-purple-600 text-white rounded-r-lg px-4 py-2 hover:bg-purple-700 transition-colors"
            >
              <Send size={20} />
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CardPage;