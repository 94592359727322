import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, Calendar, BookOpen, Smartphone, ChevronLeft, Zap, User, Loader } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useApiCall } from '../hooks/useApiCall';


const steps = [
  {
    title: "Welcome to Jurni",
    description: "Your AI-powered life journal and personal analytics companion.",
    icon: <Zap size={40} className="text-purple-500" />,
  },
  {
    title: "Personal Information",
    description: "Let's start by getting to know you a little better.",
    icon: <User size={40} className="text-blue-500" />,
    form: (
      <div className="space-y-4">
        <input type="text" placeholder="Your name" className="w-full p-2 rounded bg-gray-700 text-white" />
        <input type="date" className="w-full p-2 rounded bg-gray-700 text-white" />
      </div>
    )
  },
  {
    title: "Connect Your Calendar",
    description: "Sync your schedule to gain insights into how you spend your time.",
    icon: <Calendar size={40} className="text-green-500" />,
    action: <button className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700 transition-colors">Connect Calendar</button>
  },
  {
    title: "Journal Integration",
    description: "Import your existing journal entries or start fresh with Jurni.",
    icon: <BookOpen size={40} className="text-yellow-500" />,
    action: (
      <div className="space-x-4">
        <button className="bg-yellow-600 text-white px-4 py-2 rounded hover:bg-yellow-700 transition-colors">Import Journal</button>
        <button className="bg-gray-600 text-white px-4 py-2 rounded hover:bg-gray-700 transition-colors">Start Fresh</button>
      </div>
    )
  },
  {
    title: "Connect Your Apps",
    description: "Link your favorite apps to Jurni for a comprehensive life overview.",
    icon: <Smartphone size={40} className="text-red-500" />,
    action: <button className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition-colors">Connect Apps</button>
  },
];


const simplifiedSteps = [
  {
    title: "Welcome to Jurni",
    description: "Your AI-powered life journal and personal analytics companion.",
    icon: <Zap size={40} className="text-purple-500" />,
  },
  {
    title: "Introduce Yourself",
    description: "Tell us a bit about yourself to get started.",
    icon: <User size={40} className="text-blue-500" />,
    form: (formData, setFormData) => (
      <div className="space-y-4">
        <input
          type="text"
          value={formData.name}
          className="w-full p-2 rounded bg-gray-700 text-white"
          disabled
        />
        <textarea
          placeholder="Write a brief introduction about yourself..."
          value={formData.introduction}
          onChange={(e) => setFormData({ ...formData, introduction: e.target.value })}
          className="w-full p-2 rounded bg-gray-700 text-white h-32"
        />
      </div>
    )
  },
];

const OnboardingStep = ({ step, onNext, onPrevious, isFirst, isLast, formData, setFormData, isLoading }) => (
  <motion.div
    initial={{ opacity: 0, x: 50 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: -50 }}
    className="bg-gray-800 p-8 rounded-lg shadow-xl max-w-md w-full"
  >
    <div className="flex justify-center mb-6">{step.icon}</div>
    <h2 className="text-2xl font-bold text-white mb-4 text-center">{step.title}</h2>
    <p className="text-gray-300 mb-6 text-center">{step.description}</p>
    {step.form && <div className="mb-6">{step.form(formData, setFormData)}</div>}
    <div className="flex justify-between">
      {!isFirst && (
        <button onClick={onPrevious} className="flex items-center text-gray-400 hover:text-white transition-colors" disabled={isLoading}>
          <ChevronLeft size={20} />
          <span>Previous</span>
        </button>
      )}
      <button
        onClick={onNext}
        className={`flex items-center ${isLast ? 'bg-purple-600 text-white px-4 py-2 rounded' : 'text-gray-400 hover:text-white'} transition-colors ml-auto`}
        disabled={isLoading}
      >
        {isLoading ? (
          <Loader className="animate-spin mr-2" size={20} />
        ) : (
          <>
            <span>{isLast ? 'Get Started' : 'Next'}</span>
            {!isLast && <ChevronRight size={20} />}
          </>
        )}
      </button>
    </div>
  </motion.div>
);

const OnboardingProgress = ({ currentStep, totalSteps }) => (
  <div className="flex justify-center space-x-2 mb-8">
    {Array.from({ length: totalSteps }).map((_, index) => (
      <div
        key={index}
        className={`w-2 h-2 rounded-full ${index <= currentStep ? 'bg-purple-500' : 'bg-gray-600'}`}
      />
    ))}
  </div>
);

const Onboarding = () => {
  const { user } = useAuth0();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({ name: user.name, introduction: '' });
  const navigate = useNavigate();
  const { isLoading, error, makeApiCall } = useApiCall();

  useEffect(() => {
    if (error) {
      alert(error); // You might want to use a more user-friendly error display
    }
  }, [error]);

  const handleNext = async () => {
    if (currentStep < simplifiedSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      try {
        const response = await makeApiCall('/onboard', 'POST', formData);
        if (response) {
          navigate('/reflect');
        }
      } catch (err) {
        // Error is already handled by useApiCall
      }
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col items-center justify-center p-4">
      <OnboardingProgress currentStep={currentStep} totalSteps={simplifiedSteps.length} />
      <AnimatePresence mode="wait">
        <OnboardingStep
          key={currentStep}
          step={simplifiedSteps[currentStep]}
          onNext={handleNext}
          onPrevious={handlePrevious}
          isFirst={currentStep === 0}
          isLast={currentStep === simplifiedSteps.length - 1}
          formData={formData}
          setFormData={setFormData}
          isLoading={isLoading}
        />
      </AnimatePresence>
    </div>
  );
};

export default Onboarding;