import { motion, AnimatePresence } from 'framer-motion';

const BackgroundAnimation = () => (
  <div className="absolute inset-0 z-0 overflow-hidden">
    <motion.div
      className="absolute inset-0 bg-gradient-to-br from-purple-900 to-indigo-900"
      animate={{
        background: [
          'linear-gradient(to bottom right, #4c1d95, #312e81)',
          'linear-gradient(to bottom right, #5b21b6, #1e3a8a)',
          'linear-gradient(to bottom right, #4c1d95, #312e81)',
        ],
      }}
      transition={{ duration: 10, repeat: Infinity, ease: "easeInOut" }}
    />
    {[...Array(20)].map((_, i) => (
      <motion.div
        key={i}
        className="absolute rounded-full bg-white bg-opacity-10"
        style={{
          width: Math.random() * 100 + 50,
          height: Math.random() * 100 + 50,
          top: `${Math.random() * 100}%`,
          left: `${Math.random() * 100}%`,
        }}
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.1, 0.2, 0.1],
        }}
        transition={{
          duration: Math.random() * 10 + 10,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      />
    ))}
  </div>
);
export default BackgroundAnimation