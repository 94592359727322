import React, { useState, useCallback, useRef, useEffect } from 'react';
import Layout from '../components/shared/Layout';
import { ForceGraph2D } from 'react-force-graph';
import SmartCard from '../components/SmartCard';
import { User, MapPin, Heart, Briefcase, Book, Music, Film, Coffee, Calendar, Brain } from 'lucide-react';
import { useApiCall } from '../hooks/useApiCall';

const nodeColors = {
  User: '#FF6B6B',
  Person: '#4ECDC4',
  Place: '#45B7D1',
  Interest: '#FFA07A',
  Work: '#98D8C8',
  Hobby: '#F7DC6F',
  Event: '#C39BD3',
  Thought: '#F0B27A'
};

const getEntityIcon = (type) => {
  switch (type.toLowerCase()) {
    case 'person': return <User size={18} />;
    case 'place': return <MapPin size={18} />;
    case 'interest': return <Heart size={18} />;
    case 'work': return <Briefcase size={18} />;
    case 'hobby': return <Music size={18} />;
    case 'event': return <Calendar size={18} />;
    case 'thought': return <Brain size={18} />;
    default: return <Coffee size={18} />;
  }
};

const LifeGraphPage = () => {
  const [graphData, setGraphData] = useState({ nodes: [], links: [] });
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [hoverNode, setHoverNode] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);

  const { isLoading, error, makeApiCall } = useApiCall();
  const graphRef = useRef();
  const fetchedRef = useRef(false);

  useEffect(() => {
    const fetchGraphData = async () => {
      if (fetchedRef.current) return;
      fetchedRef.current = true;

      try {
        const response = await makeApiCall('/lifegraph/top', 'GET');
        const { nodes, links } = response;
        const formattedNodes = nodes.map(node => ({
          id: node.id,
          name: node.name,
          group: node.type,
          val: node.impact,
          ...node
        }));

        setGraphData({ nodes: formattedNodes, links });

      } catch (error) {
        console.error('Error fetching graph data:', error);
      }
    };

    fetchGraphData();
  }, [makeApiCall]);


  const updateHighlight = useCallback(() => {
    setHighlightNodes(new Set(highlightNodes));
    setHighlightLinks(new Set(highlightLinks));
  }, [highlightNodes, highlightLinks]);

  const getEntityData = useCallback((node) => ({
    name: node.name,
    type: node.group,
    icon: getEntityIcon(node.group),
    bio: node.bio || `This is a ${node.group.toLowerCase()} in your life graph.`,
    tags: node.tags || [node.group.toLowerCase()],
    impact: node.impact || Math.round(node.val * 5),
    connections: node.val
  }), []);

  const handleNodeHover = useCallback(node => {
    highlightNodes.clear();
    highlightLinks.clear();
    if (node) {
      highlightNodes.add(node);
      graphData.links.forEach(link => {
        if (link.source === node || link.target === node) {
          highlightNodes.add(link.source);
          highlightNodes.add(link.target);
          highlightLinks.add(link);
        }
      });
    }
    setHoverNode(node || null);
    updateHighlight();
  }, [graphData.links, highlightLinks, highlightNodes, updateHighlight]);

  const handleNodeClick = useCallback(node => {
    setSelectedNode(node);
    if (graphRef.current) {
      graphRef.current.centerAt(node.x, node.y, 1000);
      graphRef.current.zoom(2, 1000);
    }
  }, []);

  const nodeCanvasObject = useCallback((node, ctx, globalScale) => {
    const label = node.name;
    const fontSize = 12 / globalScale;
    ctx.font = `${fontSize}px Sans-Serif`;
    const textWidth = ctx.measureText(label).width;
    const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2);

    ctx.fillStyle = nodeColors[node.group] || '#999';
    ctx.beginPath();
    ctx.arc(node.x, node.y, 5, 0, 2 * Math.PI, false);
    ctx.fill();

    ctx.fillStyle = node.group === 'User' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)';
    ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = node.group === 'User' ? 'black' : 'white';
    ctx.fillText(label, node.x, node.y);
  }, []);

  const linkColor = useCallback((link) =>
    highlightLinks.has(link) ? 'rgba(255,255,255,0.8)' : 'rgba(255,255,255,0.2)'
    , [highlightLinks]);

  if (isLoading) {
    return (
      <Layout>
        <div className="w-full h-[calc(100vh-64px)] bg-gray-900 flex items-center justify-center">
          <div className="text-white text-2xl">Loading your life graph...</div>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="w-full h-[calc(100vh-64px)] bg-gray-900 flex items-center justify-center">
          <div className="text-white text-2xl">Error loading life graph: {error}</div>
        </div>
      </Layout>
    );
  }
  return (
    <Layout>
      <div className="w-full h-[calc(100vh-64px)] bg-gray-900 relative pt-16">
        <ForceGraph2D
          ref={graphRef}
          graphData={graphData}
          nodeRelSize={6}
          nodeCanvasObject={nodeCanvasObject}
          nodeCanvasObjectMode={() => 'replace'}
          linkColor={linkColor}
          linkWidth={link => highlightLinks.has(link) ? 2 : 1}
          linkDirectionalParticles={4}
          linkDirectionalParticleWidth={link => highlightLinks.has(link) ? 2 : 0}
          onNodeHover={handleNodeHover}
          onNodeClick={handleNodeClick}
          cooldownTicks={100}
          d3AlphaDecay={0.02}
          d3VelocityDecay={0.3}
          height={window.innerHeight - 128}
        />
        {hoverNode && (
          <div style={{
            position: 'absolute',
            top: '74px',
            left: '10px',
            zIndex: 10
          }}>
            <SmartCard
              entity={getEntityData(hoverNode)}
              onClose={() => setHoverNode(null)}
            />
          </div>
        )}
        {selectedNode && (
          <div className="absolute bottom-16 left-4 right-4 bg-gray-800 p-4 rounded-lg shadow-lg max-h-48 overflow-y-auto">
            <SmartCard
              entity={getEntityData(selectedNode)}
              onClose={() => setSelectedNode(null)}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default LifeGraphPage;