import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useApi } from '../callApi';
import { Edit2, Save, X, User, MapPin, Briefcase, Heart, Calendar } from 'lucide-react';

const SmartCard = ({ entity, onEdit, onDelete }) => {
  const getIcon = () => {
    switch (entity.type.toLowerCase()) {
      case 'person': return <User className="text-blue-500" />;
      case 'place': return <MapPin className="text-green-500" />;
      case 'work': return <Briefcase className="text-yellow-500" />;
      case 'interest': return <Heart className="text-pink-500" />;
      case 'event': return <Calendar className="text-purple-500" />;
      default: return null;
    }
  };

  return (
    <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg p-4 shadow-lg hover:shadow-xl transition-all duration-300 border border-gray-700 mb-4">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center">
          <div className="p-2 bg-gray-700 rounded-full mr-3">{getIcon()}</div>
          <h3 className="text-lg font-semibold text-white">{entity.name}</h3>
        </div>
        <div className="flex space-x-2">
          <button onClick={() => onEdit(entity)} className="text-blue-400 hover:text-blue-300">
            <Edit2 size={18} />
          </button>
          <button onClick={() => onDelete(entity.id)} className="text-red-400 hover:text-red-300">
            <X size={18} />
          </button>
        </div>
      </div>
      <p className="text-sm text-gray-400 mb-2">{entity.type}</p>
      <p className="text-sm text-gray-300">{entity.description}</p>
      {entity.metadata && (
        <div className="mt-2 flex flex-wrap gap-2">
          {Object.entries(entity.metadata).map(([key, value]) => (
            <span key={key} className="text-xs bg-gray-700 text-gray-300 px-2 py-1 rounded-full">
              {key}: {value}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

const DataReviewPage = () => {
  const { appId } = useParams();
  const navigate = useNavigate();
  const { callApi } = useApi();
  const [extractedText, setExtractedText] = useState('');
  const [entities, setEntities] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingEntity, setEditingEntity] = useState(null);

  useEffect(() => {
    // Simulating API call with sample data
    const sampleData = {
      text: "Had a great meeting with Sarah from Marketing today about the new product launch. We discussed the timeline and decided to set the launch date for July 15th. After work, I went to Central Park for a quick run to clear my head.",
      entities: [
        { id: 1, name: "Sarah", type: "Person", description: "Colleague from Marketing department", metadata: { department: "Marketing", lastInteraction: "Today" } },
        { id: 2, name: "Product Launch", type: "Event", description: "New product launch event", metadata: { date: "July 15th" } },
        { id: 3, name: "Central Park", type: "Place", description: "Public park in New York City", metadata: { activity: "Running" } },
        { id: 4, name: "Running", type: "Interest", description: "Jogging for fitness and mental clarity", metadata: { frequency: "Regular" } }
      ]
    };
    setExtractedText(sampleData.text);
    setEntities(sampleData.entities);
  }, [appId]);

  const handleEditEntity = (entity) => {
    setIsEditing(true);
    setEditingEntity(entity);
  };

  const handleDeleteEntity = (entityId) => {
    setEntities(entities.filter(e => e.id !== entityId));
  };

  const handleSaveEdit = (updatedEntity) => {
    setEntities(entities.map(e => e.id === updatedEntity.id ? updatedEntity : e));
    setIsEditing(false);
    setEditingEntity(null);
  };

  const handleSaveToGraph = async () => {
    // Simulating API call
    console.log("Saving entities to graph:", entities);
    navigate('/lifegraph/viewer');
  };

  return (
    <div className=" mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-white mb-6">Review Extracted Data</h1>

      <div className="bg-gray-800 rounded-lg p-6 mb-8">
        <h2 className="text-xl font-semibold text-white mb-4">Extracted Text</h2>
        <p className="text-gray-300 whitespace-pre-wrap">{extractedText}</p>
      </div>

      <div className="mb-8">
        <h2 className="text-xl font-semibold text-white mb-4">Extracted Entities</h2>
        {entities.map(entity => (
          <SmartCard
            key={entity.id}
            entity={entity}
            onEdit={handleEditEntity}
            onDelete={handleDeleteEntity}
          />
        ))}
      </div>

      {isEditing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 rounded-lg p-6 w-full max-w-md">
            <h3 className="text-xl font-semibold text-white mb-4">Edit Entity</h3>
            <input
              type="text"
              value={editingEntity.name}
              onChange={(e) => setEditingEntity({ ...editingEntity, name: e.target.value })}
              className="w-full bg-gray-700 text-white rounded px-3 py-2 mb-4"
            />
            <textarea
              value={editingEntity.description}
              onChange={(e) => setEditingEntity({ ...editingEntity, description: e.target.value })}
              className="w-full bg-gray-700 text-white rounded px-3 py-2 mb-4"
              rows="3"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setIsEditing(false)}
                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={() => handleSaveEdit(editingEntity)}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-end">
        <button
          onClick={handleSaveToGraph}
          className="px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors flex items-center"
        >
          <Save size={20} className="mr-2" />
          Save to Life Graph
        </button>
      </div>
    </div>
  );
};

export default DataReviewPage;