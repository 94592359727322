import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Teleprompter = ({ text, duration = 300, onComplete }) => {
  const [words, setWords] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setWords(text.split(' '));
    setCurrentIndex(0);
  }, [text]);

  useEffect(() => {
    if (currentIndex < words.length) {
      const timer = setTimeout(() => {
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, duration);

      return () => clearTimeout(timer);
    } else if (currentIndex === words.length && onComplete) {
      onComplete();
    }
  }, [currentIndex, words, duration, onComplete]);

  const getOpacity = useCallback((index) => {
    if (index === currentIndex) return 1;
    if (index < currentIndex) return 0.7;
    return 0.3;
  }, [currentIndex]);

  return (
    <div className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold text-center min-h-[12rem] sm:min-h-[16rem] flex items-center justify-center overflow-hidden px-4">
      <div className="max-w-2xl">
        <AnimatePresence>
          {words.map((word, index) => (
            <motion.span
              key={index}
              className="inline-block mx-1"
              initial={{ opacity: 0.3 }}
              animate={{ opacity: getOpacity(index) }}
              transition={{ duration: 0.3 }}
            >
              {word}
            </motion.span>
          ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Teleprompter;