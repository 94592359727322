import React, { useState } from 'react';
import Layout from '../components/shared/Layout';
import Chat from '../components/Chat';
import { Lightbulb, TrendingUp, Heart, Calendar } from 'lucide-react';

const PromptBox = ({ icon, title, description, onClick }) => (
  <div
    className="bg-gray-800 p-4 rounded-lg hover:bg-gray-700 transition-colors cursor-pointer"
    onClick={onClick}
  >
    {icon}
    <h3 className="text-white font-semibold mt-2">{title}</h3>
    <p className="text-gray-400 text-sm mt-1">{description}</p>
  </div>
);

const AskPage = () => {
  const [selectedPrompt, setSelectedPrompt] = useState('');

  const promptOptions = [
    {
      icon: <Lightbulb className="text-yellow-500" size={24} />,
      title: "Analyze my productivity patterns",
      description: "Get insights on your most productive hours and days",
      prompt: "Can you analyze my productivity patterns and tell me when I'm most productive?"
    },
    {
      icon: <TrendingUp className="text-green-500" size={24} />,
      title: "Track my personal growth",
      description: "See how you've progressed in various areas of your life",
      prompt: "How have I grown personally over the last few months?"
    },
    {
      icon: <Heart className="text-red-500" size={24} />,
      title: "Evaluate my relationships",
      description: "Understand the dynamics of your social connections",
      prompt: "Can you give me an overview of my social connections and relationships?"
    },
    {
      icon: <Calendar className="text-blue-500" size={24} />,
      title: "Plan my next week",
      description: "Get suggestions for optimizing your upcoming schedule",
      prompt: "Based on my current commitments and goals, can you help me plan my next week?"
    }
  ];

  const handlePromptClick = (prompt) => {
    setSelectedPrompt(prompt);
  };

  return (
    <Layout>
      <div className="flex flex-col h-full bg-gray-900">
        <header className="bg-gray-800 text-white p-4">
          <h1 className="text-2xl font-bold">Ask Jurni</h1>
          <p className="text-sm text-gray-400 mt-1">Explore your life's data and gain insights</p>
        </header>
        <div className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            {promptOptions.map((option, index) => (
              <PromptBox
                key={index}
                icon={option.icon}
                title={option.title}
                description={option.description}
                onClick={() => handlePromptClick(option.prompt)}
              />
            ))}
          </div>
        </div>
        <div className="flex-1 overflow-hidden px-4">
          <Chat initialInput={selectedPrompt} />
        </div>
      </div>
    </Layout>
  );
};

export default AskPage;